import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getClassName } from "..";
import { Link } from "react-router-dom";

function Section1() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(new Array(7).fill(false));

  const updateState = (i: number) => {
    const newArray = [...isOpen];
    newArray[i] = !isOpen[i];
    setIsOpen(newArray);
  };
  const Part1 = ({ i }: { i: number }) => {
    return (
      <div className={getClassName(isOpen[i])} key={i}>
        <div
          className="accordion__head"
          onClick={() => {
            updateState(i);
          }}>
          <h5>{t("faq.10")}</h5>

          <span className="accordion__toggle"></span>
        </div>
        {/* /.accordion__head */}

        <div className="accordion__body">
          <div>
            <div className="accordion__body-inner">
              <p>{t("faq.11")}</p>

              <p>{t("faq.12")}</p>

              <p>{t("faq.13")}</p>
            </div>
            {/* /.accordion__body-inner */}
          </div>
        </div>
        {/* /.accordion__body */}
      </div>
    );
  };
  const Part2 = ({ i }: { i: number }) => {
    return (
      <div className={getClassName(isOpen[i])} key={i + 10}>
        <div
          className="accordion__head"
          onClick={() => {
            updateState(i);
          }}>
          <h5>{t("faq.14")}</h5>

          <span className="accordion__toggle"></span>
        </div>
        {/* /.accordion__head */}

        <div className="accordion__body">
          <div>
            <div className="accordion__body-inner">
              <p>{t("faq.15")}</p>
            </div>
            {/* /.accordion__body-inner */}
          </div>
        </div>
        {/* /.accordion__body */}
      </div>
    );
  };
  const Part3 = ({ i }: { i: number }) => {
    return (
      <div className={getClassName(isOpen[i])} key={i + 20}>
        <div
          className="accordion__head"
          onClick={() => {
            updateState(i);
          }}>
          <h5>{t("faq.16")}</h5>

          <span className="accordion__toggle"></span>
        </div>
        {/* /.accordion__head */}

        <div className="accordion__body">
          <div>
            <div className="accordion__body-inner">
              <p>{t("faq.16")}</p>

              <ol>
                <li>{t("faq.17")}</li>

                <li>
                
                  {t("faq.18")}{" "}
                  <Link target="_blank" rel="noreferrer" to="https://www.sceneplus.ca/register">
                    {" "}
                    {t("teammateBenefits.161")}{" "}
                  </Link>
                </li>

                <li>{t("faq.20")}</li>
              </ol>
            </div>
            {/* /.accordion__body-inner */}
          </div>
        </div>
        {/* /.accordion__body */}
      </div>
    );
  };
  const Part4 = ({ i }: { i: number }) => {
    return (
      <div className={getClassName(isOpen[i])} key={i + 30}>
        <div
          className="accordion__head"
          onClick={() => {
            updateState(i);
          }}>
          <h5>{t("faq.21")}</h5>

          <span className="accordion__toggle"></span>
        </div>
        {/* /.accordion__head */}

        <div className="accordion__body">
          <div>
            <div className="accordion__body-inner">
              <p>{t("faq.22")}</p>
            </div>
            {/* /.accordion__body-inner */}
          </div>
        </div>
        {/* /.accordion__body */}
      </div>
    );
  };
  const Part5 = ({ i }: { i: number }) => {
    return (
      <div className={getClassName(isOpen[i])} key={i}>
        <div
          className="accordion__head"
          onClick={() => {
            updateState(i);
          }}>
          <h5>{t("faq.23")}</h5>

          <span className="accordion__toggle"></span>
        </div>
        {/* /.accordion__head */}

        <div className="accordion__body">
          <div>
            <div className="accordion__body-inner">
              <p>{t("faq.24")}</p>
            </div>
            {/* /.accordion__body-inner */}
          </div>
        </div>
        {/* /.accordion__body */}
      </div>
    );
  };
  const Part6 = ({ i }: { i: number }) => {
    return (
      <div className={getClassName(isOpen[i])} key={i}>
        <div
          className="accordion__head"
          onClick={() => {
            updateState(i);
          }}>
          <h5>{t("faq.25")}</h5>

          <span className="accordion__toggle"></span>
        </div>
        {/* /.accordion__head */}

        <div className="accordion__body">
          <div>
            <div className="accordion__body-inner">
              <p>{t("faq.26")}</p>
            </div>
            {/* /.accordion__body-inner */}
          </div>
        </div>
        {/* /.accordion__body */}
      </div>
    );
  };
  const Part7 = ({ i }: { i: number }) => {
    return (
      <div className={getClassName(isOpen[i])} key={i + 40}>
        <div
          className="accordion__head"
          onClick={() => {
            updateState(i);
          }}>
          <h5>{t("faq.27")}</h5>

          <span className="accordion__toggle"></span>
        </div>
        {/* /.accordion__head */}

        <div className="accordion__body">
          <div>
            <div className="accordion__body-inner">
              <p>{t("faq.28")}</p>

              <div className="table">
                <table>
                  <colgroup>
                    <col className="table__col--size1" style={{ width: "12.6%" }} />
                    <col className="table__col--size2" style={{ width: "87.4%" }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th style={{ fontWeight: "normal" }}>{t("faq.29")}</th>

                      <th>{t("faq.30")}</th>
                    </tr>

                    <tr>
                      <td>{t("faq.31")}</td>

                      <td>{t("faq.32")}</td>
                    </tr>

                    <tr>
                      <td>{t("faq.33")}</td>

                      <td>{t("faq.34")}</td>
                    </tr>

                    <tr>
                      <td>{t("faq.35")}</td>

                      <td>{t("faq.36")}</td>
                    </tr>

                    <tr>
                      <td>{t("faq.37")}</td>

                      <td>{t("faq.38")}</td>
                    </tr>

                    <tr>
                      <td>{t("faq.39")}</td>

                      <td>{t("faq.40")}</td>
                    </tr>

                    <tr>
                      <td>{t("faq.41")}</td>

                      <td>{t("faq.42")}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* /.table */}
            </div>
            {/* /.accordion__body-inner */}
          </div>
        </div>
        {/* /.accordion__body */}
      </div>
    );
  };

  return (
    <section className="section-faq">
      <div className="shell">
        <div className="section__inner">
          <div className="section__body">
            <div className="accordion js-accordion">
              <Part1 i={0} />
              <Part2 i={1} />
              <Part3 i={2} />
              <Part4 i={3} />
              <Part5 i={4} />
              <Part6 i={5} />
              <Part7 i={6} />
            </div>
            {/* /.accordion js-accordion */}
          </div>
          {/* /.section__content */}
        </div>
        {/* /.section__inner */}
      </div>
      {/* /.shell */}
    </section>
  );
}

export default Section1;
