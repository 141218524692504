import { useState } from "react";
import { useTranslation } from "react-i18next";
import Franchise from "./Tables/Franchise";
import AltTable from "./Tables/AltTable";
import CorporateTable from "./Tables/Corporate";
import { defaultStorageHelper } from "@spry/campaign-client";
import { Link } from "react-router-dom";

function SwipeSections() {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(new Array(7).fill(false));
    const banner = defaultStorageHelper.get("banner");
    const updateState = (i: number) => {
        const newArray = [...isOpen];
        newArray[i] = !isOpen[i];
        setIsOpen(newArray);
    };

    const getClassName = (isActive: boolean) => {
        return isActive ? "accordion__section is-current" : "accordion__section";
    };

    return (
        <div className="section__body">
            <div className="accordion js-accordion">
                <div className={getClassName(isOpen[0])}>
                    <div
                        className="accordion__head"
                        onClick={() => {
                            updateState(0);
                        }}>
                        <h5>{t("swipe+.58")}</h5>

                        <span className="accordion__toggle"></span>
                    </div>
                    {/* /.accordion__head */}

                    <div className="accordion__body">
                        <div>
                            <div className="accordion__body-inner">
                                <p>{t("swipe+.59")}</p>
                            </div>
                            {/* /.accordion__body-inner */}
                        </div>
                    </div>
                    {/* /.accordion__body */}
                </div>
                {/* /.accordion__section */}

                <div className={getClassName(isOpen[2])}>
                    <div
                        className="accordion__head"
                        onClick={() => {
                            updateState(2);
                        }}>
                        <h5>{t("swipe+.62")}</h5>

                        <span className="accordion__toggle"></span>
                    </div>
                    {/* /.accordion__head */}

                    <div className="accordion__body">
                        <div>
                            <div className="accordion__body-inner">
                                <p>{t("swipe+.63")}</p>
                            </div>
                            {/* /.accordion__body-inner */}
                        </div>
                    </div>
                    {/* /.accordion__body */}
                </div>
                {/* /.accordion__section */}

                <div className={getClassName(isOpen[3])}>
                    <div
                        className="accordion__head"
                        onClick={() => {
                            updateState(3);
                        }}>
                        <h5>{t("swipe+.64")}</h5>

                        <span className="accordion__toggle"></span>
                    </div>
                    {/* /.accordion__head */}

                    <div className="accordion__body">
                        <div>
                            <div className="accordion__body-inner">
                                <p>
                                    {t("swipe+.65")} <Link to={`mailto:${t("swipe+.651")}`}>{t("swipe+.651")}</Link> {t("swipe+.652")}
                                </p>
                            </div>
                            {/* /.accordion__body-inner */}
                        </div>
                    </div>
                    {/* /.accordion__body */}
                </div>
                {/* /.accordion__section */}

                <div className={getClassName(isOpen[4])}>
                    <div
                        className="accordion__head"
                        onClick={() => {
                            updateState(4);
                        }}>
                        <h5>{t("swipe+.66")}</h5>

                        <span className="accordion__toggle"></span>
                    </div>
                    {/* /.accordion__head */}

                    <div className="accordion__body">
                        <div>
                            <div className="accordion__body-inner">
                                <p>
                                    {" "}
                                    {t("swipe+.67")} <Link to={`mailto:${t("swipe+.651")}`}>{t("swipe+.651")}</Link> {t("swipe+.69")}{" "}
                                </p>
                            </div>
                            {/* /.accordion__body-inner */}
                        </div>
                    </div>
                    {/* /.accordion__body */}
                </div>
                {/* /.accordion__section */}

                <div className={getClassName(isOpen[5])}>
                    <div
                        className="accordion__head"
                        onClick={() => {
                            updateState(5);
                        }}>
                        <h5>{t("swipe+.70")}</h5>

                        <span className="accordion__toggle"></span>
                    </div>
                    {/* /.accordion__head */}

                    <div className="accordion__body">
                        <div>
                            <div className="accordion__body-inner">
                                {i18n.language === "fr" && (
                                    <p>
                                        <strong>NIVEAUX DE PRIX</strong>
                                        Le d&eacute;fi Service+ compte deux niveaux de prix : <br />
                                        le <b>niveau de performance des magasins</b> (prix remis chaque semaine)
                                        <br />
                                        le <b>niveau de loterie des magasins gagnants</b> (prix remis &agrave; la fin du d&eacute;fi)
                                    </p>
                                )}
                                <p>
                                    <b>{t("swipe+.109")}</b>
                                    <br />
                                    {t("swipe+.110")}
                                </p>
                                <p>
                                    {t("swipe+.111")}

                                    <br />
                                    {t("swipe+.112")}
                                </p>
                                <p> {t("swipe+.113")}</p>
                                <p>
                                    <b>{t("swipe+.114")}</b>
                                    <br />
                                    {t("swipe+.115")}
                                </p>

                                {/* {banner === "quebec" ? (
                                    <>
                                        <CorporateTable />
                                        <Franchise />
                                    </>
                                ) : (
                                    <AltTable />
                                )} */}
                            </div>
                            {/* /.accordion__body-inner */}
                        </div>
                    </div>
                    {/* /.accordion__body */}
                </div>
                {/* /.accordion__section */}

                <div className={getClassName(isOpen[6])}>
                    <div
                        className="accordion__head"
                        onClick={() => {
                            updateState(6);
                        }}>
                        <h5>{t("swipe+.86")}</h5>

                        <span className="accordion__toggle"></span>
                    </div>
                    {/* /.accordion__head */}

                    <div className="accordion__body">
                        <div>
                            <div className="accordion__body-inner">
                                <p>{t("swipe+.87")}</p>
                            </div>
                            {/* /.accordion__body-inner */}
                        </div>
                    </div>
                    {/* /.accordion__body */}
                </div>
                {/* /.accordion__section */}
            </div>
            {/* /.accordion js-accordion */}
        </div>
    );
}

export default SwipeSections;
