import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getClassName } from "..";

function Section3() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(new Array(4).fill(false));

  const updateState = (i: number) => {
    const newArray = [...isOpen];
    newArray[i] = !isOpen[i];
    setIsOpen(newArray);
  };

  const Part1 = ({ i }: { i: number }) => {
    return (
      <div className={getClassName(isOpen[i])} key={i}>
        <div
          className="accordion__head"
          onClick={() => {
            updateState(i);
          }}>
          <h5>{t("faq.69")}</h5>

          <span className="accordion__toggle"></span>
        </div>
        {/* /.accordion__head */}

        <div className="accordion__body">
          <div>
            <div className="accordion__body-inner">
              <p>{t("faq.70")}</p>

              <p>
                <strong>{t("faq.71")}</strong> {t("faq.72")}{" "}
              </p>

              <p>{t("faq.73")}</p>
            </div>
            {/* /.accordion__body-inner */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className="section-faq">
      <div className="shell">
        <div className="section__inner">
          <div className="section__head">
            <h4>{t("faq.68")}</h4>
          </div>
          {/* /.section__head */}

          <div className="section__body">
            <div className="accordion js-accordion">
              <Part1 i={0} />
            </div>
            {/* /.accordion js-accordion */}
          </div>
          {/* /.section__content */}
        </div>
        {/* /.section__inner */}
      </div>
      {/* /.shell */}
    </section>
  );
}

export default Section3;
