export const provincesFr: string[] = [
    "Alberta",
    "Colombie-Britannique",
    "Manitoba",
    "Nouveau-Brunswick",
    "Terre-Neuve-et-Labrador",
    "Territoires du Nord-Ouest",
    "Nouvelle-Écosse",
    "Nunavut",
    "Ontario",
    "Île-du-Prince-Édouard",
    "Québec",
    "Saskatchewan",
    "Yukon",
];

export const provincesEn: any = [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Northwest Territories",
    "Nova Scotia",
    "Nunavut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon",
];

export const BannersArray = [
    "Sobeys",
    "Foodland & Co-Op",
    "Needs",
    "Lawtons",
    "Sobeys",
    "Safeway",
    "IGA",
    "Thrifty Foods",
    "Liquor (Sobeys, Safeway, Thrifty Foods)",
    "Sobeys / Urban Fresh",
    "Foodland",
    "IGA",
    "Les Marché Traditions",
    "Rachelle Béry",
];

export const Regions = [
    {
        name: "Atlantic",
        value: "atl",
        banners: ["Sobeys", "Foodland & Co-Op", "Needs", "Lawtons"],
    },
    {
        name: "West",
        value: "west",
        banners: ["Sobeys", "Safeway", "IGA", "Thrifty Foods", "Liquor (Sobeys, Safeway, Thrifty Foods)", "FreshCo", "Chalo FreshCo"],
    },
    {
        name: "Ontario",
        value: "ont",
        banners: ["Sobeys & Urban Fresh", "Foodland & Co-Op", "FreshCo", "Chalo FreshCo"],
    },

    {
        name: "Quebec",
        value: "quebec",
        banners: ["IGA", "Les Marché Traditions", "Rachelle Béry"],
    },
];

export const RegionsTop3 = [
    {
        name: "Quebec",
        value: "quebec",
        banners: ["IGA", "Les Marché Traditions", "Rachelle Béry"],
    },
    {
        name: "Atlantic",
        value: "atl",
        banners: ["Sobeys", "Foodland", "Fust Fuels Needs", "Lawtons"],
    },
    {
        name: "Ontario",
        value: "ont",
        banners: ["Sobeys", "Foodland", "FreshCo"],
    },

    {
        name: "West",
        value: "west",
        banners: ["Sobeys", "Safeway", "IGA", "Thrifty Foods", "FreshCo", "Liquor"],
    },
];

export enum s3Host {
    'dev' = 'd2ztwr63umzbrb.cloudfront.net',
    'qa' = 'd19r6gsb6bv553.cloudfront.net',
    'prod' = 'd3tnfjs3dcqndn.cloudfront.net'
}


const env = process.env.REACT_APP_ENV || "";

const cloudfrontDomains = {
    prod: "d3tnfjs3dcqndn.cloudfront.net",
    qa: "d19r6gsb6bv553.cloudfront.net",
    dev: "d2ztwr63umzbrb.cloudfront.net",
};

export const s3 = cloudfrontDomains[env === "prod" ? "prod" : env === "qa" ? "qa" : "dev"];