import { s3Host } from "./constants";

export const convertCaps = (data: string) => {
  return data
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const ENV = /spry-dev/.test(window.location.hostname)
  ? "dev"
  : /spry-qa/.test(window.location.hostname)
    ? "qa"
    : /teammatehub/.test(window.location.hostname)
      ? "prod" :
      /espaceemployes/.test(window.location.hostname)
        ? "prod" : "dev";
export const getFileUrl = (fileUrl: string) => {

  const result = `https://${s3Host[ENV]}/${fileUrl}`

  return result;
};
