import React from "react";
import { useTranslation } from "react-i18next";
import { LotteryType } from ".";
import { Regions } from "../../../helpers/constants";

function LotteryBoard({ boardData }: { boardData: LotteryType[] }) {
    const { t } = useTranslation();
    return (
        <div className="table__content">
            <table data-number-column="false" data-layout="default" data-autosize="false" data-table-local-id="dfc23fdb-a5c4-4177-8b11-1b160bf53cc4">
                <tbody style={{ borderBottom: "2px solid #000" }}>
                    <tr>
                        <td className="pm-table-cell-content-wrap">
                            <p>
                                <strong>{t("swipe+.73")}</strong>
                            </p>
                        </td>
                        <td className="pm-table-cell-content-wrap">
                            <p>
                                <strong>{t("swipe+.74")}</strong>
                            </p>
                        </td>
                        <td className="pm-table-cell-content-wrap">
                            <p>
                                <strong>{t("swipe+.75")}</strong>
                            </p>
                        </td>
                    </tr>
                    {boardData &&
                        boardData?.length &&
                        boardData?.map((win, i) => {
                            return (
                                <React.Fragment key={i + 1}>
                                    {win.winners.map((lot, j) => (
                                        <React.Fragment key={j + 10}>
                                            {!!lot?.storeNumber ? (
                                                <tr key={j + 30}>
                                                    {j ? (
                                                        <td style={{ borderBottom: "none", borderTop: "none" }}></td>
                                                    ) : (
                                                        <td style={{ borderBottom: "none" }}>{Regions.find((r) => r.value === win.region)?.name}</td>
                                                    )}

                                                    <td className="pm-table-cell-content-wrap">{lot?.storeNumber}</td>
                                                    <td className="pm-table-cell-content-wrap">{lot?.storeName}</td>
                                                </tr>
                                            ) : (
                                                <></>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
}

export default LotteryBoard;
