import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { useRequiredCampaignState } from "../../helpers/campaignStates";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

export default function Open() {
  useRequiredCampaignState("open");
  return (
    <div className="wrapper containerWrapper">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}
