import { useTranslation } from "react-i18next";

function ContactUsSubmitted() {
  const { t } = useTranslation();
  return (
    <div className="main">
      <div className="hero hero--secondary">
        <div className="shell">
          <div className="hero__inner">
            <div className="hero__decorations">
              <div className="hero__decoration">
                <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="109" height="109" />
              </div>
              {/* /.hero__decoration */}

              <div className="hero__decoration hero__decoration--secondary">
                <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="55" height="55" />
              </div>
              {/* /.hero__decoration */}

              <div className="hero__decoration hero__decoration--tertiary">
                <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="35" height="35" />
              </div>
              {/* /.hero__decoration */}
            </div>
            {/* /.hero__decorations */}

            <div className="hero__content">
              <div className="hero__title">
                <h1>{t("contact.1")}</h1>
              </div>
              {/* /.hero__title */}
            </div>
            {/* /.hero__content */}
          </div>
          {/* /.hero__inner */}
        </div>
        {/* /.shell */}
      </div>
      <div className="white">
        <section className="section-primary white colorBlack section-form">
          <div className="section__inner">
            <div className="shell ">
              <div className="hero__inner">
                <div className="section__content">
                  <div className="hero__title  center marginTop">
                    <div className="hero__decoration">
                      <img
                        src="/assets/images/svg/check.svg"
                        alt="check"
                        width="100"
                        height="100"
                      />
                    </div>
                    <br />
                    <h2 className="colorBlack">{t("contactSubmitted.1")}</h2>
                    <br />
                    <p className="colorBlack">{t("contactSubmitted.2")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ContactUsSubmitted;
