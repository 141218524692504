import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { emailRegex, looseNorthAmericanPhoneRegex, stringRegex } from "../../../helpers/regexes";
import { useState, useEffect } from "react";
import { campaignClient, getSessionClient, setSessionClient } from "../../../api";
import Loading from "../../../Components/Loading";
import { provincesEn, provincesFr } from "../../../helpers/constants";
import { defaultStorageHelper } from "@spry/campaign-client";
import { validateExpireDate } from "../Landing";
import { Banners } from "../../../Components/Banners";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

type ContactUsFormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  banner: string;
  storeNumber: string;
  employeeNumber: string;
  province: string;
  message: string;
};

type ReturningType = { sessionKey?: string; error?: string; code?: string; message?: string };

export default function ContactUs() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactUsFormData>();

  const [submitting, setSubmitting] = useState(false);

  const checkIsLogin = () => {
    const expire = defaultStorageHelper.get("expiry");
    if (expire && validateExpireDate(expire)) {
      return;
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    checkIsLogin();
  }, []);

  async function handleForm(data: ContactUsFormData) {
    if (submitting) {
      return;
    }
    setSubmitting(true);

    try {
      const { sessionKey, error, code, message } = await campaignClient.call<ReturningType>(
        "contact",
        {
          ...data,
          banner: data.banner.split("_")[0],
          sessionKey: getSessionClient().__sessionKey,
          preferredLanguage: i18n.language,
        }
      );
      if (code && message) {
        window.vex.dialog.alert({
          unsafeMessage: message,
        });
      } else if (error && message) {
        window.vex.dialog.alert({
          unsafeMessage: message,
        });
      } else if (sessionKey) {
        setSessionClient(campaignClient.recoverSession(sessionKey));

        navigate("/contactUsSubmitted");
      } else {
        window.vex.dialog.alert({
          unsafeMessage: "Something went wrong please try again latter.",
        });
      }
    } catch {
      window.vex.dialog.alert({
        unsafeMessage: "Something went wrong please try again latter.",
      });
    }
    setSubmitting(false);
  }

  const renderProvincesDropdown = () => {
    const provinces = i18n.language === "en" ? provincesEn : provincesFr;
    return provinces.map(function (province: any) {
      return (
        <option value={province} key={province}>
          {province}
        </option>
      );
    });
  };

  return (
    <div className="main">
      {submitting && <Loading />}
      <div className="hero hero--secondary">
        <div className="shell">
          <div className="hero__inner">
            <div className="hero__decorations">
              <div className="hero__decoration">
                <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="109" height="109" />
              </div>
              {/* /.hero__decoration */}

              <div className="hero__decoration hero__decoration--secondary">
                <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="55" height="55" />
              </div>
              {/* /.hero__decoration */}

              <div className="hero__decoration hero__decoration--tertiary">
                <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="35" height="35" />
              </div>
              {/* /.hero__decoration */}
            </div>
            {/* /.hero__decorations */}

            <div className="hero__content">
              <div className="hero__title">
                <h1>{t("contact.1")}</h1>
              </div>
            </div>
            {/* /.hero__content */}
          </div>
          {/* /.hero__inner */}
        </div>
        {/* /.shell */}
      </div>
      <div className="white">
        <section className="section-primary white colorBlack section-form">
          <div className="section__inner">
            <div className="shell ">
              <div className="hero__inner">
                <div className="section__content">
                  <div className="hero__title">
                    <p className="colorBlack">{t("contact.2")}</p>
                    <p className="colorBlack">
                      {t("contact.3")}
                      <Link to={`mailto:${t("contact.4")}`}>{t("contact.4")}</Link> {t("contact.5")}
                    </p>
                  </div>

                  <p className="marginTop">
                    <small>{t("contact.6")}</small>
                  </p>

                  <div
                    className="form form-registration form-registration--alt"
                    id="form-registration">
                    <form onSubmit={handleSubmit(handleForm)}>
                      <div className="form__body">
                        <div className="form__row">
                          <label htmlFor="field-email" className="form__label">
                            {t("contact.7")} *
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder={t("contact.7")}
                              {...register("firstName", {
                                required: {
                                  value: true,
                                  message: `${t("contact.8")}`,
                                },
                                pattern: {
                                  value: stringRegex,
                                  message: `${t("contact.9")}`,
                                },
                              })}
                            />{" "}
                            {errors.firstName && (
                              <p className="error-message">
                                <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                                {errors.firstName.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="form__row">
                          <label htmlFor="field-email" className="form__label">
                            {t("contact.10")} *
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder={t("contact.10")}
                              {...register("lastName", {
                                required: {
                                  value: true,
                                  message: `${t("contact.11")}`,
                                },
                                pattern: {
                                  value: stringRegex,
                                  message: `${t("contact.12")}`,
                                },
                              })}
                            />{" "}
                            {errors.lastName && (
                              <p className="error-message">
                                <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                                {errors.lastName.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="form__row">
                          <label htmlFor="field-email" className="form__label">
                            {t("contact.13")} *
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder={t("contact.13")}
                              {...register("email", {
                                required: {
                                  value: true,
                                  message: `${t("contact.14")}`,
                                },
                                pattern: {
                                  value: emailRegex,
                                  message: `${t("contact.15")}`,
                                },
                              })}
                            />{" "}
                            {errors.email && (
                              <p className="error-message">
                                <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                                {errors.email.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="form__row">
                          <label htmlFor="field-email" className="form__label">
                            {t("contact.16")} *
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder={t("contact.16")}
                              {...register("phone", {
                                required: {
                                  value: true,
                                  message: `${t("contact.17")}`,
                                },
                                pattern: {
                                  value: looseNorthAmericanPhoneRegex,
                                  message: `${t("contact.18")}`,
                                },
                              })}
                            />{" "}
                            {errors.phone && (
                              <p className="error-message">
                                <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                                {errors.phone.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="form__row">
                          <label htmlFor="field-last-name" className="form__label">
                            {t("contact.19")} *
                          </label>
                          <div className="form__controls">
                            <div className="select">
                              <select
                                id="select-province"
                                {...register("banner", {
                                  required: {
                                    value: true,
                                    message: `${t("signup.24")}`,
                                  },
                                })}>
                                <option value="">{t("signup.11")}</option>

                                {Banners()}
                              </select>
                              <em />
                            </div>
                          </div>{" "}
                          {errors.banner && (
                            <p className="error-message">
                              <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                              {errors.banner.message}
                            </p>
                          )}
                        </div>

                        <div className="form__row">
                          <label htmlFor="field-email" className="form__label">
                            {t("contact.34")} *
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder={t("contact.34")}
                              {...register("storeNumber", {
                                required: {
                                  value: true,
                                  message: `${t("contact.35")}`,
                                },
                              })}
                            />{" "}
                            {errors.storeNumber && (
                              <p className="error-message">
                                <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                                {errors.storeNumber.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="form__row">
                          <label htmlFor="field-email" className="form__label">
                            {t("contact.36")} *
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder={t("contact.36")}
                              {...register("employeeNumber", {
                                required: {
                                  value: true,
                                  message: `${t("contact.37")}`,
                                },
                              })}
                            />{" "}
                            {errors.employeeNumber && (
                              <p className="error-message">
                                <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                                {errors.employeeNumber.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="form__row">
                          <label htmlFor="field-last-name" className="form__label">
                            {t("contact.38")} *
                          </label>
                          <div className="form__controls">
                            <div className="select">
                              <select
                                id="select-province"
                                {...register("province", {
                                  required: {
                                    value: true,
                                    message: `${t("contact.39")}`,
                                  },
                                })}>
                                <option value="">{t("contact.20")}</option>
                                {renderProvincesDropdown()}
                              </select>
                              <em />
                            </div>
                          </div>{" "}
                          {errors.province && (
                            <p className="error-message">
                              <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                              {errors.province.message}
                            </p>
                          )}
                        </div>

                        <div className="form__row">
                          <label htmlFor="field-email" className="form__label">
                            {t("contact.40")} *
                          </label>
                          <div className="form__controls">
                            <textarea
                              className="field textArea"
                              placeholder={t("contact.40")}
                              {...register("message", {
                                required: {
                                  value: true,
                                  message: `${t("contact.41")}`,
                                },

                                maxLength: {
                                  value: 150,
                                  message: "The text may not be longer than 150 characters.",
                                },
                              })}
                            />{" "}
                            {errors.message && (
                              <p className="error-message">
                                <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                                {errors.message.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div></div>
                      <br />
                      <button
                        type="submit"
                        className="btn hero__btn"
                        disabled={submitting}
                        style={{ width: "100%" }}>
                        {t("contact.42")}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
