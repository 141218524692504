import { defaultStorageHelper } from "@spry/campaign-client";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { validateExpireDate } from "../Landing";
import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { campaignClient } from "../../../api";
import Loading from "../../../Components/Loading";
import { getFileUrl } from "../../../helpers/utils";
import ReactPlayer from "react-player";
import { s3 } from "../../../helpers/constants";

type Recipe = {
    statsType: string;
    recipeName: string;
    description: string;
    recipeName_fr: string;
    description_fr: string;
    imageUrl: string;
    pdfUrl: string;
    recipe: string;
    fileName: string;
    fileSize: string;
    creationTime: string;
    pdfUrlFr: string;
    imageUrlFr: string;
    fileNameFr: string;
};

function Teammate() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.search;

    const isEnglish = i18n.language === "en";

    const checkIsLogin = () => {
        const expire = defaultStorageHelper.get("expiry");
        if (expire && validateExpireDate(expire)) {
            return;
        } else {
            navigate("/");
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        checkIsLogin();
    }, []);

    const { isLoading: loadingRecipes, data: recipes } = useQuery(
        "getRecipes",
        async () => {
            const res: { recipes: Recipe[] } = await campaignClient.call("getRecipes", {});
            return res.recipes;
        },
        {
            onError: (error) => {
                console.log(error);
            },
        }
    );

    return (
        <>
            {loadingRecipes && <Loading />}
            <div className="main">
                <div className="hero hero--secondary hero--secondary-big">
                    <div className="shell">
                        <div className="hero__inner">
                            <div className="hero__decorations">
                                <div className="hero__decoration">
                                    <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="109" height="109" />
                                </div>
                                {/* /.hero__decoration */}

                                <div className="hero__decoration hero__decoration--secondary">
                                    <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="55" height="55" />
                                </div>
                                {/* /.hero__decoration */}

                                <div className="hero__decoration hero__decoration--tertiary">
                                    <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="35" height="35" />
                                </div>
                                {/* /.hero__decoration */}
                            </div>
                            {/* /.hero__decorations */}

                            <div className="hero__content">
                                <div className="hero__title">
                                    <h1>{t("teammateBenefits.5")}</h1>
                                </div>
                                {/* /.hero__title */}

                                <div className="hero__media">
                                    <img src="/assets/images/svg/scene-fruits.svg" alt="hero-image" />
                                </div>
                                {/* /.hero__media */}

                                <p> {t("teammateBenefits.9")} </p>
                            </div>
                            {/* /.hero__content */}
                        </div>
                        {/* /.hero__inner */}
                    </div>
                    {/* /.shell */}
                </div>
                {/* /.hero */}

                <div className=" section-primary white" id="section-recipe">
                    <div className="shell">
                        <div className="section__inner">
                            <h4 tabIndex={0}>{t("teammateBenefits.56")}</h4>

                            <p>{t("teammateBenefits.57")}</p>
                            <ul>
                                <li>{t("teammateBenefits.58")}</li>
                                <li>{t("teammateBenefits.59")}</li>

                                <li>{t("teammateBenefits.60")}</li>

                                <li>{t("teammateBenefits.61")}</li>
                            </ul>

                            <p>{t("teammateBenefits.62")}</p>

                            <ul className="card__steps recipeGroup" style={{ padding: "0" }}>
                                {recipes &&
                                    recipes?.map((r) => (
                                        <li key={r.recipeName} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                            <img src={getFileUrl(!isEnglish && r?.imageUrlFr ? r.imageUrlFr : r.imageUrl)} alt={r.fileName} width={500} height={300} />
                                            <h5 style={{ marginTop: "1.5rem" }}>{isEnglish ? r.recipeName : r.recipeName_fr}</h5>
                                            <p>{isEnglish ? r.description : r.description_fr}</p>
                                            <a href={getFileUrl(!isEnglish && r?.pdfUrlFr ? r.pdfUrlFr : r.pdfUrl)} target="_blank" className="btn" style={{ maxWidth: "500px" }}>
                                                {t("teammateBenefits.63")}
                                            </a>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="section-primary white" style={{ paddingTop: "1rem" }}>
                    <div className="shell">
                        {isEnglish ? (
                            <div className="section__inner">
                                <h4 tabIndex={0} id="section-exclusive" style={{ marginBottom: "0" }}>
                                    <strong>2.Teammate Exclusives</strong>
                                </h4>
                                <p>
                                    <strong>Welcome to Teammate Exclusives, your discount program and so much more! </strong>
                                </p>
                                <div className="videoWrapper">
                                    <ReactPlayer
                                        className="react-player"
                                        url="https://vimeo.com/996669037/da33ba31d5?share=copy"
                                        type="video/mp4"
                                        playing={false}
                                        loop={false}
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                        light={true}
                                    />
                                </div>
                                <p>
                                    <strong>
                                        Teammates working in a corporate stores, RSC or CFC <br /> (Franchise teammates see below)
                                    </strong>
                                </p>

                                <p>
                                    <strong>Your weekly in-store discount</strong>
                                    <br /> Corporate teammates across Canada can benefit from a 10 per cent discount on eligible purchases at participating stores.&nbsp;The
                                    discount is applied to the first $150 of your purchases each week and resets every Sunday at midnight (Atlantic Standard Time - AST). Check your
                                    receipt after each transaction to see your remaining discount balance for the week.
                                </p>
                                <p>Note: It takes 24 hours for your discount to activate once you’ve entered your Scene+ number into the People Portal.</p>
                                <p>
                                    The weekly in-store discount is available at Sobeys, Safeway, Foodland, FreshCo, Chalo! FreshCo, IGA, IGA extra, Rachelle Béry, Lawtons, Thrifty
                                    Foods and certain Les Marchés Tradition and Marché&nbsp;Bonichoix stores.
                                </p>
                                <p />
                                <p>
                                    <strong>Your monthly Voilà discount (coming Sept. 3, 2024) </strong>
                                </p>
                                <p>
                                    Corporate teammates across Canada can also take advantage of a 10 per cent discount and free delivery on eligible purchases<sup>*</sup> when
                                    shopping with Voilà. The 10 per cent will be applied to the first $150 of each order to a maximum of four orders each month. But teammates can
                                    enjoy free pickups and delivery (where available) on all Voilà orders over $35<sup>*</sup> (before taxes and discounts)! That’s right – order as
                                    often as you need and enjoy free pickups or delivery every time<sup>*</sup>!
                                </p>
                                <p>
                                    Note: If your Scene+ number is already in the People Portal and you enjoy the in-store discount, your Voilà discount will be available within 24
                                    hours of adding your Scene+ number to your account on the Voilà website.
                                </p>
                                <p>
                                    However, you may experience a delay of up to 30 days if you still need to register your Scene+ number in the People Portal. Employee Scene+
                                    number matches are processed on the first Wednesday of each month.&nbsp;
                                </p>
                                <p>*Eligible purchases, orders, pickups, and deliveries are subject to standard Voilà terms and conditions, found at voila.ca.</p>
                                <p>
                                    <strong>Double your discount when you shop in store and online </strong>
                                </p>
                                <p>
                                    The discounts are stackable! This means you can save 10 per cent when shopping in store and an additional 10 per cent when shopping online with
                                    Voilà, while also enjoying free pickup and delivery on every order that meets the Voilà terms and conditions (found at voila.ca).
                                </p>
                                <p />
                                <p>
                                    <strong>Exclusive teammate offers</strong>
                                    <strong>
                                        &nbsp;
                                        <br />{" "}
                                    </strong>
                                    Teammates can tap into monthly offers to earn Scene+ points, get free samples, and enjoy more deals.
                                </p>
                                <p>
                                    Offers are sent by email and available on your grocery store app (e.g., Sobeys, Safeway) the first Friday of each month. You have four weeks to
                                    redeem the offers. Remember: offers can only be redeemed once.
                                </p>
                                <p>Note: It takes two weeks for your account to activate. Sign up today to receive the next available set of offers.</p>
                                <p>Teammate offers are available at Sobeys, Safeway, Foodland, IGA West, Thrifty Foods, FreshCo IGA, and IGA Extra stores.</p>
                                <p />
                                <p>
                                    <strong>How to participate:</strong>
                                </p>
                                <ol className="section__list">
                                    <li>
                                        Join <a href="https://www.sceneplus.ca/">Scene+</a>
                                    </li>
                                    <li>
                                        Register for the in-store discount by adding your Scene+ number to your profile in the{" "}
                                        <a href="https://peopleportal.sobeys.com">People Portal</a>.
                                        <ul className="card__steps">
                                            <li>If you are a Québec teammate, please provide your Scene+ number to your store administrator for registration.</li>
                                            <li>
                                                If you are a franchise teammate, please speak to your Store Operator. Note: The Voilà discount and free delivery are not available
                                                to franchise teammates.
                                            </li>
                                        </ul>
                                    </li>

                                    <li>Sign up to receive your Teammate Exclusives Scene+ offers at&nbsp;. Be sure to opt-in to receive emails. </li>
                                    <ul className="card__steps">
                                        <li>
                                            If you are a Québec teammate, please sign up to receive your Teammate Exclusives Scene+ offers at{" "}
                                            <a href="https://www.mesoffresiga.ca/">https://www.mesoffresiga.ca/</a>. Be sure to opt-in to receive emails.
                                        </li>
                                    </ul>
                                    <li>Sign up at voila.ca and include your Scene+ number to activate your Voilà discount.</li>
                                </ol>
                                <p />
                                <p>
                                    <strong>How to receive the in-store discount and redeem offers</strong>
                                </p>
                                <p>
                                    Simply scan your Scene+ card or app at the checkout to receive your core discount and redeem your offers. Check your receipt to see your
                                    remaining discount balance; log onto mygroceryoffers.ca or check the offers tab in your grocery store app to see which offers you redeemed.
                                </p>
                                <p />
                                <p>
                                    <strong>Terms &amp; Conditions</strong>
                                </p>
                                <p>
                                    Corporate teammates in our retail stores, distribution and fulfillment centres and offices are eligible to participate in Teammate Exclusives,
                                    after three months of continuous employment.
                                </p>
                                <p>
                                    Teammate Exclusives is{" "}
                                    <strong>
                                        <u>not</u>
                                    </strong>{" "}
                                    available at the following locations: Cash &amp; Carry, Pete’s Frootique &amp; Fine Foods, Fast Fuel Retail, Needs, Marché Omni, Sobeys/Safeway
                                    Liquor Stores, Boni-Soir, Voisin and Thrifty Foods Liquor. Corporate teammates that work at these banner stores can access the Teammate
                                    Exclusives program at all participating stores noted above.&nbsp;
                                </p>
                                <p>
                                    <strong>Teammates working in a franchise location</strong>
                                </p>
                                <p>
                                    Franchise locations are an important and valued part of our Sobeys family, and we are continuously exploring opportunities to support our
                                    franchise partners and their teammates.
                                </p>
                                <p>Please speak to your Franchise Operator to learn about what discount opportunities are available to you.</p>
                                <p>
                                    <strong>
                                        For more information, please visit the{" "}
                                        <a href={`https://${s3}/3a541d23576f6ae6f468c39c62edb86d/FinalEnglishFAQs_Exclusives.pdf`} target="_blanc">
                                            FAQsl
                                        </a>
                                    </strong>
                                </p>
                            </div>
                        ) : (
                            <div className="section__inner">
                                <h4 tabIndex={0} id="section-exclusive" style={{ marginBottom: "0" }}>
                                    <strong>2. Programme Exclusivités employés</strong>
                                </h4>

                                <p>
                                    <strong>Bienvenue à Exclusivités employés, votre programme de rabais et bien plus encore!</strong>
                                </p>

                                <div className="videoWrapper">
                                    <ReactPlayer
                                        className="react-player"
                                        url="https://player.vimeo.com/video/996650446?h=48f911d062&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                        type="video/mp4"
                                        playing={false}
                                        loop={false}
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                        light={true}
                                    />
                                </div>

                                <p>
                                    <strong>Employés des magasins corporatifs, des centres de distribution et des centres de traitement des commandes </strong>
                                    <br /> <strong>(Voir ci-dessous pour les employés des magasins franchisés et affiliés.)</strong>
                                </p>
                                <p>
                                    <strong>Votre rabais hebdomadaire en magasin</strong>
                                    <br /> Les employés de l’entreprise de partout au Canada* bénéficient d’un rabais de 10&nbsp;% sur les achats admissibles dans les magasins
                                    participants.&nbsp;Le rabais est appliqué à la première tranche de 150&nbsp;$ de vos achats chaque semaine et est réinitialisé tous les
                                    dimanches à minuit, heure normale de l’Atlantique (HA). Vérifiez votre reçu après chaque transaction pour voir votre solde du rabais pour la
                                    semaine.
                                </p>
                                <p>
                                    Remarque&nbsp;: Votre programme de rabais sera activé 24&nbsp;heures après que vous aurez entré votre numéro Scène+ dans Votre portail
                                    personnel.
                                </p>
                                <p>
                                    Le rabais hebdomadaire en magasin est offert chez Sobeys, Safeway, Foodland, FreshCo, Chalo!, IGA, IGA&nbsp;extra, Rachelle Béry,
                                    Thrifty&nbsp;Foods et certains magasins Les Marchés Tradition et Marché Bonichoix.
                                </p>
                                <p />
                                <p>
                                    <strong>Votre rabais mensuel Voilà (à venir le 3 septembre 2024) </strong>
                                </p>
                                <p>
                                    Les employés de l’entreprise de partout au Canada peuvent également profiter d’un rabais de 10&nbsp;% et de la livraison gratuite sur les achats
                                    admissibles* lorsqu’ils magasinent avec Voilà. Le rabais de 10&nbsp;% sera appliqué à la première tranche de 150&nbsp;$ de chaque commande,
                                    jusqu’à concurrence de quatre commandes par mois. Toutefois, les employés peuvent profiter de la cueillette et de la livraison gratuites (dans
                                    les magasins qui l’offrent) pour toutes les commandes Voilà de plus de 35&nbsp;$* (avant taxes et rabais)! C’est exact – Commandez aussi souvent
                                    que vous en avez besoin et profitez d’une cueillette ou d’une livraison gratuite à chaque fois*!
                                </p>
                                <p>
                                    Remarque&nbsp;: Si votre numéro Scène+ est déjà dans Votre portail personnel et que vous profitez du rabais en magasin, votre rabais Voilà sera
                                    applicable dans les 24&nbsp;heures suivant l’ajout de votre numéro Scène+ à votre compte sur le site Web Voilà.
                                </p>
                                <p>
                                    Toutefois, si vous devez tout de même inscrire votre numéro Scène+ dans Votre portail personnel, il pourrait y avoir un délai pouvant aller
                                    jusqu’à 30&nbsp;jours. Les numéros Scène+ des employés sont traités le premier mercredi de chaque mois.&nbsp;
                                </p>
                                <p>
                                    *&nbsp;Les achats, commandes, cueillettes et livraisons admissibles sont assujettis aux modalités standard de Voilà qui se trouvent sur
                                    voila.ca.
                                </p>
                                <p>
                                    <strong>Doublez votre rabais lors de vos achats en magasin et en ligne </strong>
                                </p>
                                <p>
                                    Les rabais sont cumulables! Cela signifie que vous pouvez économiser 10&nbsp;% en magasin et 10&nbsp;% de plus en magasinant en ligne sur Voilà,
                                    tout en profitant du service gratuit de cueillette ou de livraison sur chaque commande conforme aux modalités de Voilà (accessibles sur
                                    voilà.ca)
                                </p>
                                <p />
                                <p>
                                    <strong>Offres exclusives pour les employés</strong>&nbsp;
                                    <br /> Les employés peuvent profiter d’offres mensuelles pour accumuler des points Scène+, obtenir des échantillons gratuits et recevoir plus
                                    d’offres.
                                </p>
                                <p>
                                    Les offres sont envoyées par courriel et accessibles sur l’application de votre épicerie (p.&nbsp;ex., IGA, Sobeys, Safeway) le premier vendredi
                                    de chaque mois. Vous aurez quatre semaines pour profiter des offres. N’oubliez pas que les offres ne peuvent être échangées qu’une seule fois.
                                </p>
                                <p>
                                    Remarque&nbsp;: Il faut compter deux semaines pour que votre compte soit activé. Inscrivez-vous dès aujourd’hui pour recevoir les prochaines
                                    offres.
                                </p>
                                <p>
                                    Les offres pour les employés sont disponibles dans les magasins Sobeys, Safeway, Foodland, IGA (Ouest), Thrifty&nbsp;Foods, FreshCo, IGA et
                                    IGA&nbsp;extra.
                                </p>
                                <p />
                                <p>
                                    <strong>Voici comment participer&nbsp;:</strong>
                                </p>
                                <ol>
                                    <li>
                                        Devenez membre <a href="https://www.sceneplus.ca/fr-ca">Scène+</a>.
                                    </li>
                                    <li>
                                        Inscrivez-vous au rabais en magasin en ajoutant votre numéro Scène+ à votre profil dans{" "}
                                        <a href="https://peopleportal.sobeys.com">Votre portail personnel</a>.
                                        <ul className="card__steps">
                                            <li>
                                                Si vous êtes membre d’une équipe au Québec, veuillez fournir votre numéro Scène+ à l’administrateur de votre magasin aux fins
                                                d’inscription.
                                            </li>
                                            <li>
                                                Si vous êtes membre d’une équipe des magasins franchisés ou affiliés, veuillez communiquer avec votre exploitant de magasin.
                                                Remarque&nbsp;: Le rabais Voilà et la livraison gratuite ne sont pas offerts aux employés des magasins franchisés et affiliés.
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        Inscrivez-vous pour recevoir vos offres exclusives Scène+ à l’adresse <a href="https://www.mygroceryoffers.ca/">mesoffresiga.ca</a>.
                                        Assurez-vous d’accepter de recevoir des courriels.{" "}
                                        <ul className="card__steps">
                                            <li>
                                                Si vous êtes membre d’une équipe du Québec, inscrivez-vous pour recevoir vos offres exclusives Scène+ à l’adresse{" "}
                                                <a href="https://www.mesoffresiga.ca/">https://www.mesoffresiga.ca/</a>. Assurez-vous d’accepter de recevoir des courriels.
                                            </li>
                                        </ul>
                                    </li>

                                    <li>Inscrivez-vous sur voila.ca et ajoutez votre numéro Scène+ pour activer votre rabais Voilà.</li>
                                </ol>
                                <p />
                                <p>
                                    <strong>Comment profiter du rabais en magasin et échanger des points</strong>
                                </p>
                                <p>
                                    Il suffit de scanner votre carte ou application Scène+ à la caisse pour profiter de votre rabais de base et échanger vos points. Vérifiez votre
                                    reçu pour connaître le solde de votre rabais, ouvrez une session sur mesoffresiga.ca ou consultez l’onglet des offres dans l’application de
                                    votre magasin pour connaître les offres que vous avez échangées.
                                </p>
                                <p>
                                    <strong>Modalités</strong>
                                </p>
                                <p>
                                    Les employés de l’entreprise qui travaillent dans nos magasins, nos centres de distribution, nos centres de traitement des commandes et nos
                                    bureaux sont admissibles au programme Exclusivités employés après trois mois d’emploi continu.
                                </p>
                                <p>
                                    Le programme Exclusivités employés{" "}
                                    <strong>
                                        <u>n’est pas</u>
                                    </strong>{" "}
                                    offert aux emplacements suivants&nbsp;: Cash&nbsp;&amp;&nbsp;Carry, Pete’s Frootique&nbsp;&amp;&nbsp;Fine Foods, Fast Fuel (détail), Needs,
                                    Marché&nbsp;Omni, les magasins de vins et spiritueux Sobeys/Safeway, Boni-Soir, Voisin et les magasins de vins et spiritueux Thrifty&nbsp;Foods.
                                    Les employés de l’entreprise qui travaillent dans ces bannières peuvent accéder au programme Exclusivités employés dans tous les magasins
                                    participants mentionnés plus haut.&nbsp;
                                </p>
                                <p>
                                    <strong>Employés des magasins franchisés et affiliés</strong>
                                </p>
                                <p>
                                    Les magasins franchisés et affiliés sont un élément important et précieux de l’équipe Sobeys, et nous souhaitons saisir chaque occasion de
                                    soutenir nos partenaires franchisés et affiliés ainsi que leurs employés.
                                </p>
                                <p>Veuillez communiquer avec votre exploitant franchisé ou affilié pour connaître les possibilités de rabais qui s’offrent à vous.</p>
                                <p>
                                    <strong>
                                        Pour en savoir plus, consultez la{" "}
                                        <a href={`https://${s3}/3a541d23576f6ae6f468c39c62edb86d/FinalFrenchFAQs_Exclusives.pdf`} target="_blanc">
                                            FAQl
                                        </a>
                                    </strong>
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                {/* /.card__steps */}
                {/* <section className=" section-primary white">
                    <div className="shell">
                        <div className="section__inner">
                            <h4 tabIndex={0} id="section-discount">
                                <b>{t("teammateBenefits.10")}</b>
                            </h4>

                            <p> {t("teammateBenefits.11")}</p>

                            <p>{t("teammateBenefits.12")}</p>

                            <p>{t("teammateBenefits.13")}</p>

                            <div className="card-register section__card">
                                <h4>{t("teammateBenefits.14")}</h4>

                                <ul className="card__steps">
                                    <li>
                                        <h5>{t("teammateBenefits.15")}</h5>

                                        <p>
                                            {t("teammateBenefits.16")}
                                            <Link target="_blank" rel="noreferrer" to="https://www.sceneplus.ca/register">
                                                {" "}
                                                {t("teammateBenefits.161")}{" "}
                                            </Link>
                                        </p>
                                    </li>

                                    <li>
                                        <h5>{t("teammateBenefits.17")}</h5>

                                        <p>
                                            {" "}
                                            {t("teammateBenefits.18")}
                                            <Link target="_blank" rel="noreferrer" to="https://performancemanager4.successfactors.com/login?company=Sobeys">
                                                {" "}
                                                {t("teammateBenefits.181")}{" "}
                                            </Link>{" "}
                                            {t("teammateBenefits.182")}
                                        </p>

                                        <div className="card__image">
                                            <img src="/assets/images/svg/qr-code.svg" alt="qr-code" />
                                        </div>
                                    
                                    </li>

                                    <li>
                                        <h5>{t("teammateBenefits.19")}</h5>

                                        <p>{t("teammateBenefits.20")}</p>
                                    </li>
                                </ul>
                       
                            </div>
                         

                            <p>
                                <i>{t("teammateBenefits.21")}</i> {t("teammateBenefits.22")}{" "}
                            </p>

                            <em>{t("teammateBenefits.23")}</em>

                            <p>{t("teammateBenefits.24")}</p>

                            <p>{t("teammateBenefits.25")}</p>

                            <ol>
                                <li>{t("teammateBenefits.26")}</li>

                                <li>{t("teammateBenefits.27")}</li>

                                <li>{t("teammateBenefits.28")}</li>

                                <li>{t("teammateBenefits.29")}</li>
                            </ol>
                        </div>
                    </div>
                </section> */}
                <section className=" section-primary white">
                    <div className="shell">
                        <div className="section__inner">
                            <h4 id="section-scotia" tabIndex={0}>
                                {t("teammateBenefits.49")}
                            </h4>
                            <div className="imageContainer">
                                <div className="text">
                                    <p>{t("teammateBenefits.50")}</p>
                                    <p>{t("teammateBenefits.52")} </p>
                                    <p> {t("teammateBenefits.51")} </p>
                                    <p>
                                        {" "}
                                        {t("teammateBenefits.53")}{" "}
                                        <Link to={t("teammateBenefits.54")} target="_blank" rel="noreferrer">
                                            {t("teammateBenefits.55")}
                                        </Link>
                                    </p>{" "}
                                </div>

                                <p className="imgWrapper" style={{ marginTop: "2rem" }}>
                                    <picture>
                                        <img src="/assets/images/temp/visaMaster.jpeg" alt="card-image" />
                                    </picture>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className=" section-primary white">
                    <div className="shell">
                        <div className="section__inner">
                            <h4 id="section-scotia2">4. {t("home.s1")} </h4>
                            <p>
                                {t("home.35")} {t("home.42")}{" "}
                                <Link
                                    target="_blank"
                                    rel="noreferrer"
                                    to={
                                        i18n.language === "en"
                                            ? `https://d3tnfjs3dcqndn.cloudfront.net/3a541d23576f6ae6f468c39c62edb86d/2376474%20-%20Empire%20Employee%20Banking%20Offers_EN[38].pdf`
                                            : `https://d3tnfjs3dcqndn.cloudfront.net/3a541d23576f6ae6f468c39c62edb86d/2376474%20-%20Empire%20Employee%20Banking%20Offers_FRE[15][46].pdf`
                                    }>
                                    {t("home.421")}
                                </Link>
                            </p>
                            <p> {t("home.36")} </p>
                            <p> {t("home.37")} </p>
                            <ul className="listLineHeight">
                                <li>{t("home.38")}</li>
                                <li>{t("home.39")}</li>
                                <li>{t("home.40")}</li>
                                <li>{t("home.41")}</li>
                            </ul>

                            <p> {t("home.43")} </p>
                        </div>
                        {/* /.section__inner */}
                    </div>
                    {/* /.shell */}
                </section>
                {/* /.section-primary */}
            </div>
        </>
    );
}

export default Teammate;
