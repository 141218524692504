import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

export type Overall = { banner: string; storeNumber: string; storeName: string; week: number; swipeRate: string ; };

function OverallSwipeRate({ overall, banner, title }: { banner: string; overall: Overall[], title: string }) {
    const { t } = useTranslation();
    const filteredByBanner = useMemo(() => {
        if (overall && overall?.length) {
            return overall.filter((i: { banner: string }) => (banner.includes("Liquor") ? i?.banner.includes("Liquor") : i?.banner === banner)).sort((a, b) => a.week - b.week);
        }
    }, [banner, overall]);


    return (
        <div className="table table--awards">
            <h5>
              {t("swipe+.weekly")}  {title} - {banner?.includes("Liquor") ? "Liquor (Sobeys, Safeway, Thrifty Foods)" : banner}
            </h5>
            <div className="table__content">
                <table>
                    <colgroup>
                        <col className="table__col--size1" style={{ width: "21.5%" }} />
                        <col className="table__col--size2" style={{ width: "20%" }} />
                        <col className="table__col--size3" style={{ width: "31.3%" }} />
                        <col className="table__col--size4" style={{ width: "27.2%" }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>{t("swipe+.96")}</th>

                            <th>{t("swipe+.97")}</th>

                            <th>{t("swipe+.98")}</th>

                            <th>{t("swipe+.99")}</th>
                        </tr>
                        {filteredByBanner &&
                            filteredByBanner.map((b: Overall, k) => (
                                <tr key={k+b.storeNumber}>
                                    <td>
                                        {t("swipe+.week")} {k + 1} {t("swipe+.week2")}
                                    </td>

                                    <td>{b?.storeNumber}</td>

                                    <td>{b?.storeName}</td>

                                    <td>{b?.swipeRate}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default OverallSwipeRate;
