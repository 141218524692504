import { useTranslation } from "react-i18next";
import { useRequiredCampaignState } from "../../helpers/campaignStates";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";

export default function ComingSoon() {
    useRequiredCampaignState("comingsoon");
    const { t, i18n } = useTranslation();
    return (
        <div className="wrapper" style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <header className="header">
                <div className="shell">
                    <div className="header__inner">
                        <img src={i18n.language === "en" ? "/assets/images/svg/scene-logo.svg" : "/assets/images/svg/scene-logo-fr.svg"} alt="logo" />
                    </div>
                </div>
            </header>
            <div className="main">
                <div className="hero">
                    <div className="shell">
                        <div className="hero__inner">
                            <div className="hero__decorations">
                                <div className="hero__decoration">
                                    <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="266" height="266" />
                                </div>
                                {/* /.hero__decoration */}

                                <div className="hero__decoration hero__decoration--secondary">
                                    <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="135" />
                                </div>
                                {/* /.hero__decoration */}

                                <div className="hero__decoration hero__decoration--tertiary">
                                    <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="67" height="67" />
                                </div>
                                {/* /.hero__decoration */}
                            </div>
                            {/* /.hero__decorations */}

                            <section className="section">
                                <div className="shell">
                                    <div className="section__inner textCenter" style={{ marginTop: "10%" }}>
                                        <h3>{t("coming.1")}</h3>
                                    </div>
                                </div>
                            </section>
                        </div>
                        {/* /.hero__inner */}
                    </div>
                    {/* /.shell */}
                </div>
            </div>
            <Footer isLinks={false} noMargin={true} />
        </div>
    );
}
