import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { getSessionClient } from "./api";
import loadGtm from "./data/loadGtm";

type LocationReport = { path: string; hash?: string; search?: string };
let lastLocationReport: LocationReport = { path: "" };

export default function App() {
  const location = useLocation();

  async function reportLocation(currentLocation: LocationReport) {
    try {
      await getSessionClient()
        .createFlowBuilder()
        .setTag("navigation")
        .addMetadata(currentLocation)
        .commit();
    } catch {}
  }

  useEffect(() => {
    loadGtm();
  }, []);

  useEffect(() => {
    const currentLocation: LocationReport = { path: location.pathname };
    if (location.hash && location.hash !== "#") {
      currentLocation.hash = location.hash;
    }
    if (location.search && location.search !== "?") {
      currentLocation.search = location.search;
    }
    if (
      lastLocationReport.path !== currentLocation.path ||
      lastLocationReport.hash !== currentLocation.hash ||
      lastLocationReport.search !== currentLocation.search
    ) {
      reportLocation(currentLocation);
      lastLocationReport = currentLocation;
    }
  });

  return <Outlet />;
}
