import { Route } from "react-router-dom";
import Closed from "../Pages/Closed";
import ComingSoon from "../Pages/ComingSoon";
import Open from "../Pages/Open";
import Landing from "../Pages/Open/Landing";
import "../i18n";
import FAQ from "../Pages/Open/FAQ";
import Surprise from "../Pages/Open/Surprize";
import Teammate from "../Pages/Open/Teammate";
import ContactUs from "../Pages/Open/ContactUs";
import { Swipe } from "../Pages/Open/Swipe+";
import ContactUsSubmitted from "../Pages/Open/ContactUsSubmitted";
import Calendar from "../Pages/Open/Calendar";
import Login from "../Pages/Open/Login";

export default (
  <>
    <Route path="/" element={<Open />}>
      <Route path="/" element={<Landing />} />
      <Route path="login" element={<Login />} />
      <Route path="faq" element={<FAQ />} />
      {/* <Route path="surprise" element={<Surprise />} /> */}
      <Route path="teammate" element={<Teammate />} />  
      <Route path="contactUs" element={<ContactUs />} />
      <Route path="contactUsSubmitted" element={<ContactUsSubmitted />} />
      {/* <Route path="calendar" element={<Calendar />} /> */}
      <Route path="swipe" element={<Swipe />} />      
    </Route>

    <Route path="closed" element={<Closed />} />
    <Route path="coming" element={<ComingSoon />} />
  </>
);
