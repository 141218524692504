import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { defaultStorageHelper } from "@spry/campaign-client";
import { Link } from "react-router-dom";
import { validateExpireDate } from "../../Pages/Open/Landing";
import "./index.css";
import dayjs from "dayjs";

function Footer({ isLinks = true, noMargin=false }) {
  const { t, i18n } = useTranslation();

  const [isLogin, setIsLogin] = useState(false);
  const language = i18n.language;
  const changeLanguage = (ln: string) => {
    i18n.changeLanguage(ln);
    defaultStorageHelper.set("lang", ln);
  };

  const expire = defaultStorageHelper.get("expiry");
  const checkIsLogin = () => {
    if (expire && validateExpireDate(expire)) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  };

  useEffect(() => {
    checkIsLogin();
  }, [expire]);

  return (
    <footer className="footer footerBottom" style={noMargin ? { } : {marginTop: "auto" }}>
      <div className="shell">
        <div className="footer__inner">
          <nav className="nav-lang">
            <ul>
              <li className={language === "en" ? "is-current" : ""}>
                <span className="pointer" onClick={() => changeLanguage("en")}>
                  {t("home.30")}
                </span>
              </li>

              <li className={language === "fr" ? "is-current" : ""}>
                <span className="pointer" onClick={() => changeLanguage("fr")}>
                  {" "}
                  {t("home.31")}
                </span>
              </li>
            </ul>
          </nav>
          {/* /.nav-lang */}
          {isLinks && (
            <nav className="nav-footer footer__nav">
              <ul>
                <li>
                  <Link
                    target="_blank"
                    rel="noreferrer"
                    to={
                      language === "en"
                        ? "https://www.sceneplus.ca/terms-and-conditions"
                        : "https://www.sceneplus.ca/fr-ca/terms-and-conditions"
                    }>
                    {t("home.32")}
                  </Link>
                </li>

                {isLogin && (
                  <>
                    {" "}
                    <li>
                      <Link to="/faq">{t("home.33")}</Link>
                    </li>
                    <li>
                      <Link to="/contactUs">{t("home.7")}</Link>
                    </li>
                  </>
                )}
              </ul>
            </nav>
          )}

          {/* /.nav */}

          <p className="copyright">
            &copy; {dayjs().year()} {t("home.34")}
          </p>
          {/* /.copyright */}
        </div>
        {/* /.footer__inner */}
      </div>
      {/* /.shell */}
    </footer>
  );
}

export default Footer;
