import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getClassName } from "..";
import { Link } from "react-router-dom";

function Section7() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(new Array(4).fill(false));

  const updateState = (i: number) => {
    const newArray = [...isOpen];
    newArray[i] = !isOpen[i];
    setIsOpen(newArray);
  };

  const Part1 = ({ i }: { i: number }) => {
    return (
      <div className={getClassName(isOpen[i])} key={i}>
        <div
          className="accordion__head"
          onClick={() => {
            updateState(i);
          }}>
          <h5>{t("faq.100")}</h5>

          <span className="accordion__toggle"></span>
        </div>
        {/* /.accordion__head */}

        <div className="accordion__body">
          <div>
            <div className="accordion__body-inner">
              <p>{t("faq.101")}</p>
            </div>
            {/* /.accordion__body-inner */}
          </div>
        </div>
      </div>
    );
  };
  const Part2 = ({ i }: { i: number }) => {
    return (
      <div className={getClassName(isOpen[i])} key={i}>
        <div
          className="accordion__head"
          onClick={() => {
            updateState(i);
          }}>
          <h5>{t("faq.102")}</h5>

          <span className="accordion__toggle"></span>
        </div>
        {/* /.accordion__head */}

        <div className="accordion__body">
          <div>
            <div className="accordion__body-inner">
              <p>{t("faq.103")}</p>

              <p>{t("faq.104")}</p>
            </div>
            {/* /.accordion__body-inner */}
          </div>
        </div>
        {/* /.accordion__body */}
      </div>
    );
  };
  const Part3 = ({ i }: { i: number }) => {
    return (
      <div className={getClassName(isOpen[i])} key={i}>
        <div
          className="accordion__head"
          onClick={() => {
            updateState(i);
          }}>
          <h5>{t("faq.105")}</h5>

          <span className="accordion__toggle"></span>
        </div>
        {/* /.accordion__head */}

        <div className="accordion__body">
          <div>
            <div className="accordion__body-inner">
              <p>{t("faq.106")}</p>
            </div>
            {/* /.accordion__body-inner */}
          </div>
        </div>
      </div>
    );
  };
  const Part4 = ({ i }: { i: number }) => {
    return (
      <div className={getClassName(isOpen[i])} key={i}>
        <div
          className="accordion__head"
          onClick={() => {
            updateState(i);
          }}>
          <h5>{t("faq.107")}</h5>

          <span className="accordion__toggle"></span>
        </div>
        {/* /.accordion__head */}

        <div className="accordion__body">
          <div>
            <div className="accordion__body-inner">
              <p>{t("faq.108")}</p>

              <p>{t("faq.109")}</p>

              <p>{t("faq.110")}</p>

              <ol>
                <li>
                  {" "}
                  {t("faq.111")}   <Link target="_blank" rel="noreferrer" to={t("teammateBenefits.42")}>
                  {t("teammateBenefits.42")}
                </Link>{" "}
                  {t("faq.113")}{" "}
                </li>

                <li>{t("faq.114")}</li>

                <li>{t("faq.115")}</li>

                <li>{t("faq.116")}</li>

                <li>{t("faq.117")}</li>
              </ol>
            </div>
            {/* /.accordion__body-inner */}
          </div>
        </div>
        {/* /.accordion__body */}
      </div>
    );
  };

  return (
    <section className="section-faq">
      <div className="shell">
        <div className="section__inner">
          <div className="section__head">
            <h4>{t("faq.99")}</h4>
          </div>
          {/* /.section__head */}

          <div className="section__body">
            <div className="accordion js-accordion">
              <Part1 i={0} />
              <Part2 i={1} />
              <Part3 i={2} />
              <Part4 i={3} />
            </div>
            {/* /.accordion js-accordion */}
          </div>
          {/* /.section__content */}
        </div>
        {/* /.section__inner */}
      </div>
      {/* /.shell */}
    </section>
  );
}

export default Section7;
