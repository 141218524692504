import { useTranslation } from "react-i18next";
import React from "react";
import SwipeSections from "../../../Components/SwipeSections";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { campaignClient } from "../../../api";
import Loading from "../../../Components/Loading";
import { defaultStorageHelper } from "@spry/campaign-client";
import { validateExpireDate } from "../Landing";
import { useNavigate } from "react-router-dom";
import ModalLanguage from "../../../Components/ModalLanguage";
import { getFrenchDate } from "../../../hooks/getFrenchDate";
import BannerDropDown from "../../../Components/BannerDropDown";
import SwipeRateIncrease, { Overall } from "./overallSwipeRate";
import { useQuery } from "react-query";
import LotteryBoard from "./lotteryBoard";
import { convertCaps } from "../../../helpers/utils";

declare const window: Window &
    typeof globalThis & {
        vex: any;
    };

type ReturnCalendar = {
    calendar: Calendar[];
    grandPrizeDate: string;
    lotteryDate: string;
    top3Date: string;
};

export type LotteryType = {
    region: string;
    winners: {
        storeNumber: string;
        storeName: string;
    }[];
};

type Calendar = {
    week: number;
    endDate: string;
    startDate: string;
    announcementDate: string;
};

type GetLotteryWinners = { type: "grandPrize-swipe" | "grandprize-overall" | "lottery-winners" | "lottery-target" };

export function Swipe() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const bannerLocal = defaultStorageHelper.get("banner");
    const colors = ["green", "blue", "yellow", "red", "purple", "lightBlue"];
    const [region, setRegion] = useState("atl");
    const [banner, setBanner] = useState("Sobeys");
    const [isLoading, setIsLoading] = useState(false);
    const [rulesModal, setRulesModal] = useState(false);
    const [showBanners, setShowBanners] = useState(false);

    function formDate(date: string) {
        return dayjs(date).isValid() ? dayjs(date).format("MMMM DD, YYYY") : "Invalid Date";
    }
    useEffect(() => {
        checkIsLogin();
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    const { isLoading: loadingCalendar, data: calendarData } = useQuery(
        "getSwipePlus",
        async () => {
            const res: ReturnCalendar = await campaignClient.call("getSwipplus", {});
            return res;
        },
        {
            onError: (error) => {
                console.log(error);
            },
        }
    );

    const { isLoading: loadingOverall, data: overall } = useQuery(
        ["getOverall", region],

        async () => {
            const { leaderboard }: { leaderboard: { winners: Overall[] } } = await campaignClient.call("getSwipeRateWinners", {
                region,
                type: "weekly-overall",
            });
            return leaderboard.winners;
        },
        {
            onError: (error) => {
                console.log(error);
            },
        }
    );
    const { isLoading: loadingWeekly, data: weekly } = useQuery(
        ["getWeekly", region],

        async () => {
            const { leaderboard }: { leaderboard: { winners: Overall[] } } = await campaignClient.call("getSwipeRateWinners", {
                region,
                type: "weekly-swipe",
            });
            return leaderboard.winners;
        },
        {
            onError: (error) => {
                console.log(error);
            },
        }
    );

    async function getLotteryBoard({ type }: GetLotteryWinners) {
        const { winners }: { winners: LotteryType[] } = await campaignClient.call("getLotteryWinners", {
            type,
        });
        return winners;
    }

    const { isLoading: loadingGrandSwipe, data: grandSwipe } = useQuery("getGrandSwipe", async () => {
        const winners = await getLotteryBoard({ type: "grandPrize-swipe" });
        return winners;
    });
    const { isLoading: loadingGrandPrizeOverall, data: grandPrizeOverall } = useQuery("getGrandPrizeOverall", async () => {
        const winners = await getLotteryBoard({ type: "grandprize-overall" });
        return winners;
    });
    const { isLoading: loadingLotteryWinner, data: lotteryWinner } = useQuery("getLotteryWinner", async () => {
        const winners = await getLotteryBoard({ type: "lottery-winners" });
        return winners;
    });
    const { isLoading: loadingLotteryTarget, data: lotteryTarget } = useQuery("getPrizeOverall", async () => {
        const winners = await getLotteryBoard({ type: "lottery-target" });
        return winners;
    });

    const checkIsLogin = () => {
        const expire = defaultStorageHelper.get("expiry");
        if (expire && validateExpireDate(expire)) {
            return;
        } else {
            navigate("/");
        }
    };

    async function getPdf(dataType: string, banner?: string) {
        setIsLoading(true);
        rulesModal && setRulesModal(false);
        try {
            const { url }: { url: string } = await campaignClient.call(
                "getPdfFiles",

                {
                    type: "swipe",
                    dataType,
                    language: i18n.language,
                    region: dataType === "leaderboard" ? region : bannerLocal === "quebec" && banner ? banner : bannerLocal,
                }
            );
            setIsLoading(false);
            if (url) {
                setTimeout(() => {
                    window.open(url, "_blank");
                });
            } else {
                window.vex.dialog.alert({
                    unsafeMessage: "There are no file",
                });
            }
        } catch (e) {
            window.vex.dialog.alert({
                unsafeMessage: "Please try again later.",
            });
            setIsLoading(false);
        }
    }

    return (
        <div className="main">
            {(loadingCalendar || isLoading || loadingWeekly || loadingOverall) && <Loading />}
            {rulesModal && (
                <ModalLanguage>
                    <div
                        className="popup popup--alt textCenter"
                        style={{
                            position: "relative",
                            backgroundColor: "#fff",
                            padding: "30px",
                        }}>
                        <a
                            style={{ position: "absolute", right: "2.5rem", top: "2.5rem" }}
                            onClick={() => {
                                setRulesModal(false);
                            }}
                            className="popup__close">
                            <img src="/assets/images/svg/ico-x.svg" alt="" width="20" />
                        </a>
                        <div className="popup__content" style={{ paddingTop: "2rem" }}>
                            <h3>{t("swipe+.642")}</h3>
                        </div>
                        <div
                            className="popup__actions"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                                paddingTop: "30px",
                            }}>
                            <button
                                style={{ width: "40%" }}
                                type="button"
                                className="btn btn--purple"
                                onClick={() => {
                                    getPdf("rules", "corporate");
                                }}>
                                {t("swipe+.643")}
                            </button>
                            <button
                                style={{ width: "40%" }}
                                type="button"
                                className="btn btn--outline"
                                onClick={() => {
                                    getPdf("rules", "franchise");
                                }}>
                                {t("swipe+.641")}
                            </button>
                        </div>
                    </div>
                </ModalLanguage>
            )}
            <div className="hero hero--secondary">
                <div className="shell">
                    <div className="hero__inner">
                        <div className="hero__decorations">
                            <div className="hero__decoration">
                                <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="109" height="109" />
                            </div>
                            {/* /.hero__decoration */}

                            <div className="hero__decoration hero__decoration--secondary">
                                <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="55" height="55" />
                            </div>
                            {/* /.hero__decoration */}

                            <div className="hero__decoration hero__decoration--tertiary">
                                <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="35" height="35" />
                            </div>
                            {/* /.hero__decoration */}
                        </div>
                        {/* /.hero__decorations */}

                        <div className="hero__content">
                            <div className="hero__title">
                                <h1>{t("swipe+.2")}</h1>
                            </div>
                            {/* /.hero__title */}
                        </div>
                        {/* /.hero__content */}
                    </div>
                    {/* /.hero__inner */}
                </div>
                {/* /.shell */}
            </div>
            {/* /.hero */}

            <section className="section-primary section-primary--alt">
                <div className="shell">
                    <div className="section__inner">
                        <a href="/swipe#leaderboard" className="btn">
                            {t("swipe+.9")}
                        </a>
                        <br />
                        <br />
                        <p>{t("swipe+.10")}</p>

                        <p>{t("swipe+.11")}</p>

                        <p>
                            {t("swipe+.12")} <strong>{t("home.17")}</strong>
                            {t("swipe+.14")}
                        </p>

                        <div className="table-program">
                            <table>
                                <tbody>
                                    <>
                                        {calendarData &&
                                            calendarData?.calendar?.length &&
                                            calendarData?.calendar.map(({ week, endDate, startDate, announcementDate }: Calendar, i) => (
                                                <React.Fragment key={i + 5}>
                                                    {endDate && startDate && announcementDate ? (
                                                        <>
                                                            <tr className={`${colors[i]} table__head`} key={i + endDate}>
                                                                <th>
                                                                    {t("swipe+.15")} {i + 1}
                                                                </th>

                                                                <th></th>
                                                            </tr>
                                                            <tr className={`light-${colors[i]} `}>
                                                                <td colSpan={2}>
                                                                    <div className="table__row">
                                                                        <strong>{t("swipe+.16")}</strong>

                                                                        <span>{i18n.language === "en" ? formDate(startDate) : getFrenchDate(startDate)}</span>
                                                                    </div>

                                                                    <div className="table__row">
                                                                        <strong>{t("swipe+.18")}</strong>

                                                                        <span>{i18n.language === "en" ? formDate(endDate) : getFrenchDate(endDate)}</span>
                                                                    </div>

                                                                    <div className="table__row">
                                                                        <strong>{t("swipe+.20")}</strong>

                                                                        <span>{i18n.language === "en" ? formDate(announcementDate) : getFrenchDate(announcementDate)}</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                    </>
                                </tbody>

                                <tfoot>
                                    {calendarData?.grandPrizeDate ? (
                                        <>
                                            <tr className="black table__head">
                                                <th colSpan={2}>{t("swipe+.31")}</th>
                                            </tr>
                                            <tr className="grey">
                                                <td colSpan={2}>
                                                    <div className="table__row">
                                                        {calendarData && (
                                                            <span>
                                                                {i18n.language === "en" ? formDate(calendarData?.grandPrizeDate) : getFrenchDate(calendarData?.grandPrizeDate)}
                                                            </span>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    ) : (
                                        <></>
                                    )}

                                    {calendarData?.lotteryDate ? (
                                        <>
                                            <tr className="black table__head">
                                                <th colSpan={2}>{t("swipe+.33")}</th>
                                            </tr>
                                            <tr className="grey">
                                                <td colSpan={2}>
                                                    <div className="table__row">
                                                        {calendarData && (
                                                            <span>{i18n.language === "en" ? formDate(calendarData?.lotteryDate) : getFrenchDate(calendarData?.lotteryDate)}</span>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    ) : (
                                        <></>
                                    )}

                                    {calendarData?.top3Date && (
                                        <>
                                            <tr className="black table__head">
                                                <th colSpan={2}>{t("swipe+.32")}</th>
                                            </tr>
                                            <tr className="grey">
                                                <td colSpan={2}>
                                                    <div className="table__row">
                                                        {calendarData && (
                                                            <span>{i18n.language === "en" ? formDate(calendarData?.top3Date) : getFrenchDate(calendarData?.top3Date)}</span>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </tfoot>
                            </table>
                        </div>

                        <h5>{t("swipe+.34")}</h5>

                        <ul>
                            <li>{t("swipe+.35")}</li>

                            <li>{t("swipe+.36")}</li>

                            <li>{t("swipe+.37")}</li>

                            <li>{t("swipe+.38")}</li>
                        </ul>

                        <h5>{t("swipe+.39")}</h5>

                        <div className="table table--ranking">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>{t("swipe+.40")}</th>

                                        <th>A</th>

                                        <th>B</th>

                                        <th>C</th>
                                    </tr>

                                    <tr>
                                        <td>{t("swipe+.41")}</td>

                                        <td>65%</td>

                                        <td>40%</td>

                                        <td>35%</td>
                                    </tr>

                                    <tr>
                                        <td>{t("swipe+.42")}</td>

                                        <td>70%</td>

                                        <td>55%</td>

                                        <td>45%</td>
                                    </tr>

                                    <tr>
                                        <td>{t("swipe+.43")}</td>

                                        <td>5%</td>

                                        <td>15%</td>

                                        <td>10%</td>
                                    </tr>

                                    <tr>
                                        <td>{t("swipe+.44")}</td>

                                        <td>3</td>

                                        <td>1</td>

                                        <td>2</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* /.table */}

                        <h5>{t("swipe+.45")}</h5>

                        <p>{t("swipe+.46")}</p>
                        <div className="section1-pt">
                            <ul style={{ paddingInlineStart: "4.3rem" }}>
                                <li>
                                    {" "}
                                    <p>
                                        <b> {t("swipe+.47")}</b>
                                        <span> {t("swipe+.47-1")}</span>
                                    </p>
                                </li>
                                <li>
                                    {" "}
                                    <p>
                                        <b> {t("swipe+.48")}</b>
                                        <span> {t("swipe+.48-1")}</span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <br />
                        <h5>{t("swipe+.50title")}</h5>
                        <p>{t("swipe+.117")}</p>
                        <div className="imageContainer">
                            <p>
                                <span style={{ textDecoration: "underline" }}> {t("swipe+.118")}</span>
                                {t("swipe+.118-1")}
                            </p>
                            <p className="imgWrapper">
                                <picture>
                                    <img src="/assets/images/temp/visaMaster.jpeg" alt="card-image" width="350px" />
                                </picture>
                            </p>
                        </div>
                        <br />

                        <div className="imageContainer">
                            <p>
                                <span style={{ textDecoration: "underline" }}> {t("swipe+.119")}</span>
                                {t("swipe+.119-1")}
                            </p>
                            <p className="imgWrapper">
                                <picture>
                                    <img src="/assets/images/temp/visaMaster.jpeg" alt="card-image" width="350px" />
                                </picture>
                            </p>
                        </div>
                        <br />
                        {t("swipe+.120")}
                        <br />
                        <br />

                        <h5>{t("swipe+.53title")}</h5>
                        <div className="imageContainer">
                            <p>
                                {t("swipe+.121")}
                                {/* {i18n.language === "fr" && <span style={{ whiteSpace: "nowrap" }}>{t("swipe+.1000")}</span>} */}
                            </p>
                            <p className="imgWrapper" style={{ textAlign: "center" }}>
                                <picture>
                                    <img src="/assets/images/temp/scene-plus-symbol.avif" alt="card-image" width="200px" />
                                </picture>
                            </p>
                        </div>

                        <span className="line"></span>

                        <p>{t("swipe+.56")}</p>

                        <a href="/swipe#leaderboard" className="btn">
                            {t("swipe+.9")}
                        </a>
                    </div>
                    {/* /.section__inner */}
                </div>
                {/* /.shell */}
            </section>
            {/* /.section-primary */}

            <section className="section-faq">
                <div className="shell">
                    <div className="section__inner">
                        <div className="section__head">
                            <h4> {t("swipe+.57")} </h4>
                        </div>

                        <SwipeSections />

                        <div className="section__foot">
                            <p>{t("swipe+.88")}</p>

                            <button className="btn" onClick={() => (bannerLocal === "quebec" ? setRulesModal(true) : getPdf("rules"))}>
                                {t("swipe+.89")}
                            </button>
                        </div>
                        {/* /.section__foot */}
                    </div>
                    {/* /.section__inner */}
                </div>
                {/* /.shell */}
            </section>
            {/* /.section-faq */}

            <section className="section-alt">
                <div className="shell">
                    <div className="section__inner">
                        <div className="section__head">
                            <h4 id="leaderboard">{t("swipe+.90")}</h4>
                        </div>
                        {/* /.section__head */}

                        <div className="section__body">
                            <form
                                onSubmit={async () => {
                                    await getPdf("leaderboard");
                                }}>
                                <div className="form__head">
                                    <h6>{t("swipe+.91")}</h6>
                                </div>
                                {/* /.form__head */}

                                <div className="form__body">
                                    <div className="form__row">
                                        <label htmlFor="field-last-name" className="form__label">
                                            {t("signup.10")} *
                                        </label>
                                        <div className="form__controls">
                                            <div className="select">
                                                <input
                                                    className="field"
                                                    placeholder={t("signup.11")}
                                                    // onChange={() => {
                                                    //   setValue("banner", "");
                                                    // }}

                                                    value={convertCaps(banner ?? "")}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowBanners(!showBanners);
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                />

                                                <em />
                                            </div>
                                            {showBanners && <BannerDropDown isSwipe={true} setRegion={setRegion} setBanner={setBanner} setShowBanners={setShowBanners} />}
                                        </div>
                                    </div>

                                    {/* /.form__controls */}
                                    <br />
                                    <div className="form__actions">
                                        {/* OnClick has to be changed!!! */}
                                        {/* {leaderboardUrl ? ( */}
                                        <button type="button" onClick={() => getPdf("leaderboard")} className="btn" style={{ width: "100%" }}>
                                            {t("swipe+.94")}
                                        </button>
                                        {/* ) : (
                                            <p className="btn">{t("login.leaderboardComing")}</p>
                                        )} */}
                                    </div>
                                    <br />
                                    {/* /.form__actions */}
                                </div>
                                {/* /.form__body */}
                            </form>
                            {/* /.form-update-table */}

                            <div className="section__tables flexTables">
                                {weekly && <SwipeRateIncrease overall={weekly} banner={banner} title={t("swipe+.108")} />}

                                {overall && <SwipeRateIncrease overall={overall} banner={banner} title={t("swipe+.107")} />}

                                {grandSwipe ? (
                                    <div className=" table--awards table">
                                        <h5>
                                            {t("swipe+.72")}
                                            {t("swipe+.108")}
                                        </h5>

                                        {grandSwipe && grandSwipe?.length ? <LotteryBoard boardData={grandSwipe} /> : <></>}
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {grandPrizeOverall ? (
                                    <div className=" table--awards table">
                                        <h5>
                                            {t("swipe+.72")}
                                            {t("swipe+.472")}
                                            {t("swipe+.winners")}
                                        </h5>
                                        {grandPrizeOverall && grandPrizeOverall?.length ? <LotteryBoard boardData={grandPrizeOverall} /> : <></>}
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {lotteryWinner ? (
                                    <div className=" table--awards table">
                                        <h5>{t("swipe+.lottery1")}</h5>
                                        {lotteryWinner && lotteryWinner?.length ? <LotteryBoard boardData={lotteryWinner} /> : <></>}
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {lotteryTarget ? (
                                    <div className=" table--awards table">
                                        <h5>{t("swipe+.lottery2")}</h5>

                                        {lotteryTarget && lotteryTarget?.length ? <LotteryBoard boardData={lotteryTarget} /> : <></>}
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <div className=" table--awards table">
                                    <h5>{t("swipe+.116")}</h5>
                                </div>
                            </div>
                            {/* /.section__tables */}
                        </div>
                        {/* /.section__body */}
                    </div>
                    {/* /.section__inner */}
                </div>
                {/* /.shell */}
            </section>
            {/* /.section-faq */}
        </div>
    );
}
