import { BannersArray } from "../../helpers/constants";

export function Banners() {
  const sortArray = (array: string[]) => array.sort((a, b) => a.localeCompare(b));
  return sortArray(BannersArray).map((banner) => (
    <>
      <option value={banner}>{banner}</option>
    </>
  ));
}
