import { defaultStorageHelper } from "@spry/campaign-client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { validateExpireDate } from "../../Pages/Open/Landing";

function Header() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function logOut() {
    defaultStorageHelper.remove("expiry");
    defaultStorageHelper.remove("banner");
    setIsLogin(false);
    navigate("/login");
  }

  const expire = defaultStorageHelper.get("expiry");

  useEffect(() => {
    if (expire && validateExpireDate(expire)) {
      setIsLogin(true);
    }
  }, [expire]);

  return (
    <header className="header">
      <div className="shell">
        <div className="header__inner">
          <Link to="/" className="logo">
            <img
              src={
                i18n.language === "en"
                  ? "/assets/images/svg/scene-logo.svg"
                  : "/assets/images/svg/scene-logo-fr.svg"
              }
              alt="logo"
            />
          </Link>

          <div
            className={isMenuOpen ? "header__nav is-active" : "header__nav"}
            onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <nav className="nav">
              <ul>
                <li>
                  <Link to="/">{t("home.1")}</Link>
                </li>
                {isLogin ? (
                  <>
                    <li>
                      <Link to="/swipe">{t("home.2")}</Link>
                    </li>
                    {/* <li>
                      <Link to="/surprise">{t("home.3")}</Link>
                    </li> */}
                    {/* <li>
                      <Link to="/calendar">{t("home.4")}</Link>
                    </li> */}
                    <li>
                      <Link to="/teammate">{t("home.5")}</Link>
                    </li>
                    <li>
                      <Link to="/faq">{t("home.6")}</Link>
                    </li>
                    <li>
                      <Link to="/contactUs">{t("home.7")}</Link>
                    </li>
                    <li>
                      <a onClick={logOut}>{t("home.8")}</a>
                    </li>{" "}
                  </>
                ) : (
                  <li>
                    <Link to="/login">{t("home.13")}</Link>
                  </li>
                )}
              </ul>
            </nav>
            {/* /.nav */}
          </div>
          {/* /.header__nav */}

          <a onClick={() => setIsMenuOpen(!isMenuOpen)} className="nav-trigger js-nav-trigger">
            <span></span>

            <span></span>

            <span></span>
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;
