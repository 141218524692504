import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { AllSections } from "../../../Components/FAQSections";
import { defaultStorageHelper } from "@spry/campaign-client";
import { validateExpireDate } from "../Landing";
import { useNavigate } from "react-router-dom";

function FAQ() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const checkIsLogin = () => {
    const expire = defaultStorageHelper.get("expiry");
    if (expire && validateExpireDate(expire)) {
      return;
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    checkIsLogin();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="main">
      <div className="hero hero--secondary">
        <div className="shell">
          <div className="hero__inner">
            <div className="hero__decorations">
              <div className="hero__decoration">
                <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="109" height="109" />
              </div>
              {/* /.hero__decoration */}

              <div className="hero__decoration hero__decoration--secondary">
                <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="55" height="55" />
              </div>
              {/* /.hero__decoration */}

              <div className="hero__decoration hero__decoration--tertiary">
                <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="35" height="35" />
              </div>
              {/* /.hero__decoration */}
            </div>
            {/* /.hero__decorations */}

            <div className="hero__content">
              <div className="hero__title">
                <h1>{t("faq.9")}</h1>
              </div>
              {/* /.hero__title */}
            </div>
            {/* /.hero__content */}
          </div>
          {/* /.hero__inner */}
        </div>
        {/* /.shell */}
      </div>
      {/* /.hero */}

      <div className="white">{AllSections}</div>
    </div>
  );
}

export default FAQ;
