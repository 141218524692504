import dayjs from "dayjs";

export function getFrenchDate(eventDate: string) {
  const FrMonth = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];

  const date = dayjs(eventDate).date() === 1 ? "1er" : dayjs(eventDate).date();
  const year = dayjs(eventDate).year();
  const month = dayjs(eventDate).month();
  const frMonth = FrMonth[month];
  return `${date} ${frMonth} ${year}`;
}
