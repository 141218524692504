import { defaultStorageHelper } from "@spry/campaign-client";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import ModalLanguage from "../../../Components/ModalLanguage";

export function validateExpireDate(date: string) {
    return dayjs().isBefore(dayjs(date).add(1, "day"));
}

export default function Landing() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(false);
    const [isLanguageModal, setIsLanguageModal] = useState(false);
    useEffect(() => {
        const expire = defaultStorageHelper.get("expiry");
        if (expire && validateExpireDate(expire)) {
            setIsLogin(true);
            window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
            navigate("/login");
        }
        const language = defaultStorageHelper.get("lang");

        !language && window.location.host.includes("espaceemployes") && i18n.changeLanguage("fr");
        !language && setIsLanguageModal(true);
    }, []);

    const changeLanguage = (ln: string) => {
        i18n.changeLanguage(ln);
        defaultStorageHelper.set("lang", ln);
        setIsLanguageModal(false);
    };

    return (
        <div className="main blackBackground">
            {(isLanguageModal || !isLogin) && (
                <ModalLanguage>
                    <div
                        className="popup popup--alt textCenter"
                        style={{
                            position: "relative",
                            backgroundColor: "#fff",
                            padding: "30px",
                        }}>
                        <div className="popup__content">
                            <h3>{t("home.45")}</h3>
                        </div>
                        <div
                            className="popup__actions"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                                paddingTop: "30px",
                            }}>
                            <button
                                style={{ width: "40%" }}
                                type="button"
                                className="btn btn--purple"
                                onClick={() => {
                                    changeLanguage("en");
                                }}>
                                {t("home.46")}
                            </button>{" "}
                            <button
                                style={{ width: "40%" }}
                                type="button"
                                className="btn btn--outline"
                                onClick={() => {
                                    changeLanguage("fr");
                                }}>
                                {t("home.47")}
                            </button>
                        </div>
                    </div>
                </ModalLanguage>
            )}
            <div className="hero">
                <div className="shell">
                    <div className="hero__inner">
                        <div className="hero__decorations">
                            <div className="hero__decoration">
                                <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="266" height="266" />
                            </div>
                            {/* /.hero__decoration */}

                            <div className="hero__decoration hero__decoration--secondary">
                                <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="135" />
                            </div>
                            {/* /.hero__decoration */}

                            <div className="hero__decoration hero__decoration--tertiary">
                                <img src="/assets/images/svg/scene-dots.svg" alt="dots" width="67" height="67" />
                            </div>
                            {/* /.hero__decoration */}
                        </div>
                        {/* /.hero__decorations */}

                        <div className="hero__content colorWhite">
                            <div className="hero__title">
                                <img src={i18n.language === "en" ? "/assets/images/svg/scene-title.svg" : "/assets/images/svg/scene-title-fr.svg"} alt="title" />
                            </div>
                            {/* /.hero__title */}

                            <div className="hero__media">
                                <img src="/assets/images/svg/scene-teammates.svg" alt="hero-image" />
                            </div>
                            {/* /.hero__media */}

                            <p>{t("home.9")}</p>
                            <p>{t("home.9-2")}</p>

                            <p>{t("home.10")}</p>

                            <p>{t("home.11")}</p>

                            {!isLogin && (
                                <div className="hero__actions">
                                    <Link to="/login" className="btn hero__btn">
                                        {t("home.12")}
                                    </Link>
                                </div>
                            )}
                            {/* /.hero__actions */}
                        </div>
                        {/* /.hero__content */}
                    </div>
                    {/* /.hero__inner */}
                </div>
                {/* /.shell */}
            </div>
            {/* /.hero */}

            <section className="section">
                <div className="shell">
                    <div className="section__inner">
                        <div className="section__head colorWhite">
                            <h3>{t("home.14")}</h3>
                        </div>
                        {/* /.section__head */}

                        <div className="section__body">
                            <div className="grid">
                                <div className="grid__cols">
                                    <div className="grid__col grid__col--1of2">
                                        <div className="card card--secondary">
                                            <h2>{t("home.2")}</h2>

                                            <div className="card__image">
                                                <picture>
                                                    <source srcSet="/assets/images/svg/scene-image-1.svg" media="(min-width: 768px)" />
                                                    <img src="/assets/images/svg/scene-image-1-mobile.svg" alt="card-image" />
                                                </picture>
                                            </div>
                                            {/* /.card__image */}

                                            <div className="card__content colorWhite">
                                                <p> {t("home.15")} </p>

                                                <p>
                                                    {" "}
                                                    {t("home.16")}
                                                    <strong>{t("home.17")}</strong>
                                                    {t("home.18")}
                                                </p>

                                                <p>{t("home.19")}</p>
                                            </div>
                                            {/* /.card__content */}

                                            <div className="card__actions">
                                                <Link to="/swipe" className="btn hero__btn">
                                                    {t("home.20")}
                                                </Link>
                                            </div>
                                            {/* /.card__actions */}
                                        </div>
                                        {/* /.card */}
                                    </div>
                                    {/* /.grid__col */}

                                    {/* <div className="grid__col grid__col--1of2">
                                        <div className="card card--green">
                                            <h2>{t("home.3")}</h2>

                                            <div className="card__image" style={i18n.language === "fr" ? { margin: "5rem 0" } : {}}>
                                                <picture>
                                                    <source srcSet="/assets/images/svg/scene-cake.svg" media="(min-width: 768px)" />
                                                    <img src="/assets/images/svg/scene-cake-mobile.svg" alt="card-image" />
                                                </picture>
                                            </div>
                                         

                                            <div className="card__content colorWhite">
                                                <p>
                                                    {t("home.21")}
                                                    <br />
                                                    {t("home.22")}
                                                </p>
                                            </div>
                                          

                                            <div className="card__actions" style={{ marginTop: "5rem" }}>
                                                {isLogin ? (
                                                    <Link to="/surprise" className="btn">
                                                        {t("home.20")}
                                                    </Link>
                                                ) : (
                                                    <Link to="/login" className="btn hero__btn">
                                                        {t("home.20")}
                                                    </Link>
                                                )}
                                            </div>
                                       
                                        </div>
                                       
                                    </div> */}
                                    {/* /.grid__col */}
                                </div>
                                {/* /.grid__cols */}
                            </div>
                            {/* /.grid */}
                        </div>
                        {/* /.section__body */}
                    </div>
                    {/* /.section__inner */}
                </div>
                {/* /.shell */}
            </section>
            {/* /.section */}

            <section className="section">
                <div className="shell">
                    <div className="section__inner">
                        <div className="section__head colorWhite">
                            <h3>{t("home.5")}</h3>
                        </div>
                        {/* /.section__head */}

                        <div className="section__body">
                            <div className="grid">
                                <div className="grid__cols">
                                    {/* /.grid__col */}

                                    <div className="grid__col grid__col--1of2">
                                        <div className="card card--pink">
                                            <h2>{t("home.25")}</h2>

                                            <div className="card__image">
                                                <picture>
                                                    {/* <source
                            srcSet="/assets/images/temp/basket-alt.png"
                            media="(min-width: 768px)"
                          /> */}
                                                    <img
                                                        src={i18n.language === "en" ? "/assets/images/temp/basket-alt-mobile.png" : "/assets/images/temp/exclusive_fr.png"}
                                                        alt="card-image"
                                                        width="300"
                                                    />
                                                </picture>
                                            </div>
                                            {/* /.card__image */}

                                            <div className="card__content colorWhite">
                                                <p>
                                                    {t("home.26")}
                                                    <br />
                                                    {t("home.27")}
                                                </p>
                                            </div>
                                            {/* /.card__content */}

                                            <div className="card__actions marginTop54">
                                                <Link to={isLogin ? "/teammate#section-exclusive" : "/login"} className="btn hero__btn">
                                                    {t("home.20")}
                                                </Link>
                                            </div>
                                            {/* /.card__actions */}
                                        </div>
                                        {/* /.card */}
                                    </div>

                                    <div className="grid__col grid__col--1of2">
                                        <div className="card card--green recipe">
                                            <h2>{t("teammateBenefits.56m")}</h2>
                                            <br />
                                            <div className="card__image">
                                                <picture>
                                                    {/* <source
                            srcSet="/assets/images/temp/basket-alt.png"
                            media="(min-width: 768px)"
                          /> */}
                                                    <img src="/assets/images/temp/recipes1.png" alt="card-image" width="300" />
                                                </picture>
                                            </div>
                                            {/* /.card__image */}

                                            <div className="card__content colorWhite">
                                                <p>
                                                    {t("home.26")}
                                                    <br />
                                                    {t("home.27")}
                                                </p>
                                            </div>
                                            {/* /.card__content */}

                                            <div className="card__actions marginTop54">
                                                <Link to={isLogin ? "/teammate#section-recipe" : "/login"} className="btn hero__btn">
                                                    {t("home.20")}
                                                </Link>
                                            </div>
                                            {/* /.card__actions */}
                                        </div>
                                        {/* /.card */}
                                    </div>
                                    {/* /.grid__col */}
                                    <div className="grid__col grid__col--1of2">
                                        <div className="card card--yellow">
                                            <h2 className="marginBottom5">{t("home.23")}</h2>

                                            <div className="card__image" style={{ margin: "3.6rem 0" }}>
                                                <picture>
                                                    <source srcSet="/assets/images/temp/basket.png" media="(min-width: 768px)" />
                                                    <img src="/assets/images/temp/basket-mobile.png" alt="card-image" width="300" />
                                                </picture>
                                            </div>
                                            {/* /.card__image */}

                                            <div className="card__content colorWhite">
                                                <p>{t("home.24")}</p>
                                            </div>
                                            {/* /.card__content */}

                                            <div className="card__actions marginTop54">
                                                <Link to="/teammate#section-discount" className="btn">
                                                    {t("home.20")}
                                                </Link>
                                            </div>
                                            {/* /.card__actions */}
                                        </div>
                                        {/* /.card */}
                                    </div>

                                    <div className="grid__col grid__col--1of2">
                                        <div className="card card--red colorWhite">
                                            <h2>{t("home.28")}</h2>

                                            <div className="card__image" style={i18n.language === "en" ? { margin: "3.5rem 0 " } : { margin: "4.5rem" }}>
                                                <picture>
                                                    <source srcSet="/assets/images/svg/scene-card.svg" media="(min-width: 768px)" />
                                                    <img src="/assets/images/svg/scene-card-mobile.svg" alt="card-image" />
                                                </picture>
                                            </div>
                                            {/* /.card__image */}

                                            <div className="card__content colorWhite">
                                                <p> {t("home.35")} </p>
                                            </div>
                                            {/* /.card__content */}

                                            <div className="card__actions">
                                                <Link to={isLogin ? "/teammate#section-scotia" : "/login"} className="btn hero__btn">
                                                    {t("home.20")}
                                                </Link>
                                            </div>
                                            {/* /.card__actions */}
                                        </div>
                                        {/* /.card */}
                                    </div>

                                    <div className="grid__col grid__col--1of2">
                                        <div className="card card--secondary colorWhite">
                                            <h2>{t("home.s1")}</h2>

                                            <div className="card__image">
                                                <picture>
                                                    <img src="/assets/images/scotiabank-icon.svg" alt="card-image" />
                                                </picture>
                                            </div>
                                            {/* /.card__image */}

                                            <div className="card__content colorWhite">
                                                <p> {t("home.29")} </p>
                                            </div>
                                            {/* /.card__content */}

                                            <div className="card__actions">
                                                <Link to={isLogin ? "/teammate#section-scotia2" : "/login"} className="btn hero__btn">
                                                    {t("home.20")}
                                                </Link>
                                            </div>
                                            {/* /.card__actions */}
                                        </div>
                                        {/* /.card */}
                                    </div>
                                </div>
                                {/* /.grid__cols */}
                            </div>
                            {/* /.grid */}
                        </div>
                        {/* /.section__body */}
                    </div>
                    {/* /.section__inner */}
                </div>
                {/* /.shell */}
            </section>
            {/* /.section */}
        </div>
    );
}
