import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section7 from "./Section7";
import Section6 from "./Section6";
import Section8 from "./Section8";

export const getClassName = (isActive: boolean) => {
  return isActive ? "accordion__section is-current" : "accordion__section";
};

export const AllSections = [
  <Section1 key={100}/>,
  <Section2 key={101}/>,
  <Section3 key={102}/>,
  <Section4 key={103}/>,
  <Section5 key={104}/>,
  <Section6 key={105}/>,
  <Section7 key={106}/>,
  <Section8 key={107}/>,
];
