import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getClassName } from "..";

function Section2() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(new Array(4).fill(false));

  const updateState = (i: number) => {
    const newArray = [...isOpen];
    newArray[i] = !isOpen[i];
    setIsOpen(newArray);
  };

  const Part1 = ({ i }: { i: number }) => {
    return (
      <div className={getClassName(isOpen[i])} key={i + 50}>
        <div
          className="accordion__head"
          onClick={() => {
            updateState(i);
          }}>
          <h5>{t("faq.44")}</h5>

          <span className="accordion__toggle"></span>
        </div>
        {/* /.accordion__head */}

        <div className="accordion__body">
          <div>
            <div className="accordion__body-inner">
              <p>{t("faq.45")}</p>

              <div className="table">
                <table>
                  <colgroup>
                    <col className="table__col--size1" style={{ width: "12.6%" }} />
                    <col className="table__col--size2" style={{ width: "87.4%" }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td>{t("faq.46")}</td>

                      <td>{t("faq.47")}</td>
                    </tr>

                    <tr>
                      <td>{t("faq.48")}</td>

                      <td>{t("faq.49")}</td>
                    </tr>

                    <tr>
                      <td>{t("faq.50")}</td>

                      <td>{t("faq.51")}</td>
                    </tr>

                    <tr>
                      <td>{t("faq.52")}</td>

                      <td>{t("faq.53")}</td>
                    </tr>

                    <tr>
                      <td>{t("faq.37")}</td>

                      <td>{t("faq.38")}</td>
                    </tr>

                    <tr>
                      <td>{t("faq.54")}</td>

                      <td>{t("faq.55")}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* /.table */}
            </div>
            {/* /.accordion__body-inner */}
          </div>
        </div>
      </div>
    );
  };
  const Part2 = ({ i }: { i: number }) => {
    return (
      <div className={getClassName(isOpen[i])} key={i + 60}>
        <div
          className="accordion__head"
          onClick={() => {
            updateState(i);
          }}>
          <h5>{t("faq.56")}</h5>

          <span className="accordion__toggle"></span>
        </div>
        {/* /.accordion__head */}

        <div className="accordion__body">
          <div>
            <div className="accordion__body-inner">
              <p>{t("faq.57")}</p>
              <p>{t("faq.58")}</p>
            </div>
            {/* /.accordion__body-inner */}
          </div>
        </div>
        {/* /.accordion__body */}
      </div>
    );
  };
  const Part3 = ({ i }: { i: number }) => {
    return (
      <div className={getClassName(isOpen[i])} key={i+79}>
        <div
          className="accordion__head"
          onClick={() => {
            updateState(i);
          }}>
          <h5>{t("faq.59")}</h5>

          <span className="accordion__toggle"></span>
        </div>
        {/* /.accordion__head */}

        <div className="accordion__body">
          <div>
            <div className="accordion__body-inner">
              <p>
                <em>{t("faq.60")}</em>
                <br />
                {t("faq.61")}
              </p>

              <p>
                <em>{t("faq.62")}</em>
                <br />
                {t("faq.63")}
              </p>

              <p> {t("faq.64")} </p>

              <p>{t("faq.65")}</p>
            </div>
            {/* /.accordion__body-inner */}
          </div>
        </div>
        {/* /.accordion__body */}
      </div>
    );
  };
  const Part4 = ({ i }: { i: number }) => {
    return (
      <div className={getClassName(isOpen[i])} key={i+ 80}>
        <div
          className="accordion__head"
          onClick={() => {
            updateState(i);
          }}>
          <h5>{t("faq.66")}</h5>

          <span className="accordion__toggle"></span>
        </div>
        {/* /.accordion__head */}

        <div className="accordion__body">
          <div>
            <div className="accordion__body-inner">
              <p>{t("faq.67")}</p>
            </div>
            {/* /.accordion__body-inner */}
          </div>
        </div>
        {/* /.accordion__body */}
      </div>
    );
  };

  return (
    <section className="section-faq">
      <div className="shell">
        <div className="section__inner">
          <div className="section__head">
            <h4>{t("faq.43")}</h4>
          </div>
          {/* /.section__head */}

          <div className="section__body">
            <div className="accordion js-accordion">
              <Part1 i={0} />
              <Part2 i={1} />
              <Part3 i={2} />
              <Part4 i={3} />
            </div>
            {/* /.accordion js-accordion */}
          </div>
          {/* /.section__content */}
        </div>
        {/* /.section__inner */}
      </div>
      {/* /.shell */}
    </section>
  );
}

export default Section2;
