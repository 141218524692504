const loadGtm = () => {
  let tag = "";

  const defaultLanguageEN = window.location.href.includes(
    "teammatehub"
  );

  const defaultLanguageFR = window.location.href.includes(
    "espaceemployes"
  );

  if (defaultLanguageEN) {
    tag = "G-4S0Q52WLND"; //en
  }

  if (defaultLanguageFR) {
    tag = "'G-1NV3S0F5XW"; //fr
  }

  const existingScript = document.getElementById("scriptGtm");

  const existingNoScript = document.getElementById("noScriptGtm");

  if (!existingScript) {
    const script = document.createElement("script");
    script.id = "scriptGtm";
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${tag}');`;
    script.async = true;
    document.head.appendChild(script);
  }

  if (!existingNoScript) {
    const script = document.createElement("noscript");
    script.id = "noScriptGtm";
    script.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${tag}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.prepend(script);
  }
};

export default loadGtm;
